import { Help } from "@mui/icons-material";
import React from "react";

type DescriptionType = {
  handleClick: () => void;
};

export const VerifiedDescription = ({ handleClick }: DescriptionType) => {
  return (
    <div>
      <div
        className="bg-[#2B8C34] border-[1px] border-[#2B8C34] text-[#fff] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <p className="text-white leading-5 text-sm font-medium">Verified</p>
        <Help
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const ActiveDescription = ({ handleClick }: DescriptionType) => {
  return (
    <div>
      <div
        className="bg-[#2B8C34] border-[1px] border-[#2B8C34] text-[#fff]   cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <p className="text-white leading-5 text-sm font-medium">Active</p>
        <Help
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const UnverifiedDescription = ({ handleClick }: DescriptionType) => {
  return (
    <div>
      <div
        className="bg-[#B92020] border-[1px] border-[#B92020] text-[#fff] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <p className="text-white leading-5 text-sm font-medium">Unverified</p>
        <Help
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const FraudulentDescription = ({ handleClick }: DescriptionType) => {
  return (
    <div>
      <div
        className="bg-[#B92020] border-[1px] border-[#B92020] text-[#fff] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <p className="text-white leading-5 text-sm font-medium">
          Likely Fraudulent
        </p>
        <Help
          style={{
            fontSize: "16px",
            color: "white",
          }}
        />
      </div>
    </div>
  );
};

export const IncompleteDescription = ({ handleClick }: DescriptionType) => {
  return (
    <div>
      <div
        className="bg-[#FFC508] border-[1px] border-[#FFC508] text-[#fff] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1"
        onClick={handleClick}
      >
        <p className=" leading-5 text-sm font-medium">Incomplete</p>
        <Help
          style={{
            fontSize: "16px",
          }}
        />
      </div>
    </div>
  );
};

import Header from "../../../components/Header";

import AdminAgentsTable from "../../../tables/Admin/Agents";

const AdminAgentsPage = () => {
  return (
    <>
      <Header text="Agents" />

      {/* Table */}
      <AdminAgentsTable />
    </>
  );
};

export default AdminAgentsPage;

import React, { useState } from "react";
import GoBack from "../../../../components/GoBack";
import AdminFarmerInformationPage from "./FarmerAccountInformation";
import AdminFarmDetailsPage from "./FarmDetails";
import { useParams } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ModalComponent from "../../../../components/Modals/modal";
import { toast } from "react-hot-toast";
import AdminFarmReportPage from "./Report";

const AdminFarmersProfile = () => {
  type languageDataType = {
    id: string;
    name: string;
  };

  type templatesType = {
    body: string;
    language: string;
  };

  type templateType = {
    title: string;
    templates: templatesType[];
  };

  const templateData = [
    {
      id: "33",
      created: "2024-01-17T11:42:26.901Z",
      updated: "2024-01-17T11:42:26.901Z",
      templates: [
        {
          body: "Complete Registration",
          language: "English",
        },
      ],

      title: "Kindly complete your registration",

      user: { first_name: "Omotayo", last_name: "Olaniyan" },
      user_id: 415,
    },
  ];

  const { id, phone_number } = useParams();
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState("account_information");
  const [farmerDetails, setFarmerDetails] = useState<any>(null);

  ////////////////////////////////////////////// LANGUAGE
  const [toggleLanguageIcon, setToggleLanguageIcon] = useState(false);
  const [toggleTemplateIcon, setToggleTemplateIcon] = useState(false);
  const languageData = [
    { id: "eng", name: "English" },
    { id: "yor", name: "Yoruba" },
    { id: "hau", name: "Hausa" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState({
    id: "",
    name: "",
  });
  const [selectedTemplate, setSelectedTemplate] = useState<templateType>({
    title: "",
    templates: [],
  });
  const [message, setMessage] = useState("");

  const handleOpenLanguage = () => {
    if (Object.keys(selectedTemplate).length !== 0) {
      setToggleLanguageIcon(!toggleLanguageIcon);
    } else {
      toast.error("Kindly select a template first");
    }
  };

  const handleSelectLanguage = (langData: languageDataType) => {
    let result = selectedTemplate.templates.filter(
      (data) => data.language === langData.name
    );
    setMessage(result.length !== 0 ? result[0].body : "");
    setSelectedLanguage(langData);
    setToggleLanguageIcon(false);
  };

  const handleSendMessage = () => {
    // const value = {
    //   message,
    //   phone_numbers: [phone_number],
    // };

    toast.success("Message sent successfully");
    setShow(false);

    // dispatch(sendSingleMessage({ value }))
    //   .then((res) => {
    //     if (res?.payload?.error) {
    //       cogoToast.error(res?.payload?.error);
    //       setShow(false);
    //     } else {
    //       setMessage("");
    //       setSelectedLanguage({});
    //       setSelectedTemplate({});
    //       cogoToast.success("Message sent successfully");
    //       setShow(false);
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response.status === 401) {
    //       dispatch({ type: "auth/Logout" });
    //     }
    //     cogoToast.error(error?.response?.data.error);
    //   });
  };

  const handleGetDetails = (details: any) => {
    setFarmerDetails(details);
  };

  return (
    <>
      <GoBack />

      <div className="bg-white mt-5 rounded-[8px] px-4 py-4">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-primary text-lg font-semibold leading-6">
              Farmer Profile
              {farmerDetails?.first_name &&
                ` | ${farmerDetails?.first_name} ${farmerDetails?.last_name} (${farmerDetails?.phone_number}) `}
            </p>
          </div>
          <div className="flex gap-4">
            <a href={`tel:${phone_number}`}>
              <button
                type="submit"
                className="bg-primary 
                                    disabled:bg-[#ABB6AC] 
                                    rounded-lg py-3 px-6 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
              >
                Call Farmer
              </button>
            </a>
            <button
              onClick={() => setShow(true)}
              type="submit"
              className="bg-transparent 
                                    disabled:bg-[#ABB6AC] 
                                    rounded-lg py-3 px-6 border border-primary text-primary text-sm font-medium hover:bg-[#24752B] hover:text-white transition-all"
            >
              Send Message
            </button>
          </div>
        </div>

        {/* tabs */}
        <div className="flex gap-4 mt-4 items-center">
          <p
            className={
              tab === "account_information"
                ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
            }
            onClick={() => setTab("account_information")}
          >
            Account Information
          </p>
          <p
            className={
              tab === "farm_details"
                ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
            }
            onClick={() => setTab("farm_details")}
          >
            Farm Details
          </p>
          <p
            className={
              tab === "report"
                ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
            }
            onClick={() => setTab("report")}
          >
            Report
          </p>
        </div>
      </div>

      <div className="mt-4">
        {tab === "account_information" ? (
          <div>
            <AdminFarmerInformationPage
              id={id}
              phoneNumber={phone_number}
              onSelect={handleGetDetails}
            />
          </div>
        ) : tab === "farm_details" ? (
          <div>
            <AdminFarmDetailsPage id={id} />
          </div>
        ) : tab === "report" ? (
          <div>
            <AdminFarmReportPage id={id} />
          </div>
        ) : null}
      </div>

      {/* SEND MESSAGE MODAL */}
      <ModalComponent
        title="Compose Message"
        subtitle=""
        isOpen={show}
        onClose={() => setShow(false)}
      >
        {/* MESSAGE TEMPLATE */}
        <div className="pt-[16px] pb-[10px]">
          <div className="relative w-full">
            <p className="font-[400] text-sm leading-[18px] text-[#344335]">
              Message Template
            </p>
            <div
              className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[6px]"
              onClick={() => {
                setToggleTemplateIcon(!toggleTemplateIcon);
              }}
            >
              <p>{selectedTemplate.title || "Select a template"}</p>

              {toggleTemplateIcon ? (
                <ExpandLess
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              ) : (
                <ExpandMore
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              )}
            </div>

            {toggleTemplateIcon && (
              <div
                className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                style={{
                  boxShadow:
                    "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                }}
              >
                {templateData.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                      onClick={() => {
                        setSelectedTemplate(data);
                        setToggleTemplateIcon(false);
                      }}
                    >
                      <p className="font-medium text-sm leading-5 text-[#344335]">
                        {data.title}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* LANG */}
        <div className="pb-[10px]">
          <div className="relative w-full">
            <p className="font-[400] text-sm leading-[18px] text-[#344335]">
              Select Language
            </p>
            <div
              className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[6px]"
              onClick={handleOpenLanguage}
            >
              <p>{selectedLanguage.name || "Language"}</p>

              {toggleLanguageIcon ? (
                <ExpandLess
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              ) : (
                <ExpandMore
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              )}
            </div>

            {toggleLanguageIcon && (
              <div
                className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                style={{
                  boxShadow:
                    "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                }}
              >
                {languageData.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                      onClick={() => handleSelectLanguage(data)}
                    >
                      <p className="font-medium text-sm leading-5 text-[#344335]">
                        {data.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* MESSAGE */}
        <div className="pb-[10px]">
          <textarea
            className="resize-none h-[134px] disabled:bg-[#F7F7F7] outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
            placeholder="Message"
            onChange={(event) => setMessage(event.target.value)}
            value={message}
          />
        </div>

        <div className="flex items-center justify-end gap-[24px] my-6">
          <button
            type="submit"
            disabled={message.length !== 0 ? false : true}
            onClick={message ? handleSendMessage : () => {}}
            className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Send
          </button>
        </div>
      </ModalComponent>
    </>
  );
};

export default AdminFarmersProfile;

import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
  useMemo,
} from "react";
import ModalComponent from "../../../components/Modals/modal";
import { FormatShapes } from "@mui/icons-material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  retrieveLgas,
  retrieveStates,
  retrieveCrops,
  retrieveLivestock,
} from "../../../redux/Auth/authActions";
import go_back from "../../../assets/icons/goBack.svg";
import add_circle from "../../../assets/icons/add_circle.svg";
import warning from "../../../assets/icons/warning.svg";
import { Formik, Form, FormikHelpers } from "formik";
import { registerFarmerValidator } from "../../../validationSchema/validator";
import toast from "react-hot-toast";
import {
  captureFarm,
  fetchAgentFarmers,
  registerSelfFarmers,
} from "../../../redux/Farmers/farmerActions";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Marker,
  Pin,
  MapCameraProps,
  MapCameraChangedEvent,
  InfoWindow,
  useMarkerRef,
} from "@vis.gl/react-google-maps";
import { Circle } from "../../../components/Circle/circle";
import { Polygon } from "../../../components/Polygon/polygon";
import moment from "moment";

type CaptureType = {
  isCreateCat: boolean;
  onClose: () => void;
};
const DataCapturePage = ({ isCreateCat, onClose }: CaptureType) => {
  const dispatch = useAppDispatch();
  const { states, lgas, crops, livestock } = useAppSelector(
    (state) => state.auth
  );

  // const [farmerId, setFarmerID] = useState("");
  const [loader, setLoader] = useState(false);
  const [farmloader, setFarmLoader] = useState(false);
  const API_KEY = "AIzaSyCAyoLnnbfQjG7R1caK4_FrGsVFIdbRObY";

  type FilterType = {
    id: number;
    tag: string;
    text: string;
  };
  const [filterId, setFilterId] = useState<number | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  const [filterType] = useState<FilterType[]>([
    {
      id: 1,
      tag: "new",
      text: "New Farmer",
    },
    {
      id: 2,
      tag: "old",
      text: "Existing Farmer",
    },
  ]);

  const [isShow, setShow] = React.useState(false);
  const [isCaptureModal, setIsCaptureModal] = React.useState(false);
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });

  const INITIAL_CAMERA = {
    zoom: 19,
    center: { lat: 0, lng: 0 },
  };

  const [cameraProps, setCameraProps] =
    useState<MapCameraProps>(INITIAL_CAMERA);

  const [infowindowOpen, setInfowindowOpen] = useState(true);
  const [markerRef, marker] = useMarkerRef();

  const [radius, setRadius] = React.useState(20);

  const changeCenter = (newCenter: google.maps.LatLng | null) => {
    if (!newCenter) return;
    setCameraProps({
      zoom: 19,
      center: { lng: newCenter.lng(), lat: newCenter.lat() },
    });
  };

  const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
    setCameraProps(ev.detail);
  }, []);

  useEffect(() => {
    setCameraProps({
      zoom: 19,
      center: userLocation,
    });
  }, [userLocation]);

  const showCaptureModal = () => {
    setIsCaptureModal(!isCaptureModal);
  };

  const handleContinue = () => {
    onClose();

    setTimeout(() => {
      setShow(true);
    }, 500);
  };

  const showModal = () => {
    setShow(!isShow);
  };

  const toggleFilterType = (val: FilterType) => {
    const { id, text } = val;
    setFilterId(id);
    setSelectedFilter(text);
  };

  const [steps, setSteps] = useState(1);
  const [isToggleGender, setIsToggleGender] = useState(false);
  const [isToggleState, setIsToggleState] = useState(false);
  const [isToggleLGA, setIsToggleLGA] = useState(false);
  const [isToggleCrops, setIsToggleCrops] = useState(false);
  const [isToggleLivestock, setIsToggleLivestock] = useState(false);
  const [isToggleSeason, setIsToggleSeason] = useState(false);

  const [gender] = useState<
    {
      id: string;
      name: string;
      tag: string;
    }[]
  >([
    {
      id: "1",
      tag: "male",
      name: "Male",
    },
    {
      id: "2",
      tag: "female",
      name: "Female",
    },
  ]);

  const [selectedGender, setSelectedGender] = useState<{
    name: string;
    id: string;
    tag: string;
  }>({
    name: "",
    id: "",
    tag: "",
  });

  const handleSelectedGender = (name: string, id: string, tag: string) => {
    setSelectedGender({
      name: name,
      id: id,
      tag: tag,
    });
    setIsToggleGender(false);
  };

  const [season] = useState<
    {
      id: string;
      name: string;
      tag: string;
    }[]
  >([
    {
      id: "1",
      tag: "dry",
      name: "Dry Season",
    },
    {
      id: "2",
      tag: "wet",
      name: "Wet Season",
    },
    {
      id: "2",
      tag: "wet_and_dry",
      name: "Dry and Wet Season",
    },
  ]);

  const [selectedSeason, setSelectedSeason] = useState<{
    name: string;
    id: string;
    tag: string;
  }>({
    name: "",
    id: "",
    tag: "",
  });

  const handleSelectedSeason = (name: string, id: string, tag: string) => {
    setSelectedSeason({
      name: name,
      id: id,
      tag: tag,
    });
    setIsToggleSeason(false);
  };

  const [selectedState, setSelectedState] = useState<{
    name: string;
    id: number;
    display_name?: string;
  }>({
    name: "",
    id: 0,
    display_name: "",
  });

  const handleSelectedState = (name: string, id: number) => {
    setSelectedState({
      name: name,
      id: id,
    });
    setIsToggleState(false);
    dispatch(retrieveLgas({ id: id }));
  };

  const [selectedLGA, setSelectedLGA] = useState<{
    name: string;
    id: number;
    display_name?: string;
  }>({
    name: "",
    id: 0,
    display_name: "",
  });

  const handleSelectedLGA = (name: string, id: number) => {
    setSelectedLGA({
      name: name,
      id: id,
    });
    setIsToggleLGA(false);
  };

  const identificationRef = useRef<HTMLDivElement>(null);
  const [isToggleIdentification, setIsToggleIdentification] = useState(false);
  const [identificationNo, setIdentificationNo] = useState<string>("");
  const [passport, setPassport] = useState<string | Blob>("");
  const [identificationPhotograph, setIdentificationPhotograph] = useState<
    string | Blob
  >("");

  const identification = [
    {
      id: "driver_license",
      name: "Driver's License",
    },
    {
      id: "nin",
      name: "NIN",
    },
    {
      id: "voters_card",
      name: "Voter's Card",
    },
    {
      id: "international_passport",
      name: "International Passport",
    },
  ];

  const [file, setFile] = useState<string | null>(null);
  const [identificationFile, setIdentificationFile] = useState<string | null>(
    null
  );

  const [selectedIdentification, setSelectedIdentification] = useState<{
    name: string;
    id: string;
  }>({
    name: "",
    id: "",
  });

  const handleSelectedIdentification = (name: string, id: string) => {
    setSelectedIdentification({
      name: name,
      id: id,
    });
    setIdentificationFile(null);
    setIsToggleIdentification(false);
  };

  const handleSetFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files[0]) {
      setPassport(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = function (e) {
        setFile(e?.target?.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSetIdentificationFile = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files && event.target.files[0]) {
      setIdentificationPhotograph(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = function (e) {
        setIdentificationFile(e?.target?.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleIdNumber = (e: any) => {
    setIdentificationNo(e.target.value);
  };

  const [selectedCrops, setSelectedCrops] = useState<
    Array<{
      name: string;
    }>
  >([]);

  const handleSelectedCrop = (name: string) => {
    const isCropSelected = selectedCrops.some((tag) => tag.name === name);

    if (isCropSelected) {
      setSelectedCrops((prevCrops) =>
        prevCrops.filter((tag) => tag.name !== name)
      );
    } else {
      setSelectedCrops((prevCrops) => [...prevCrops, { name }]);
    }
  };

  const [selectedLivestock, setSelectedLivestock] = useState<
    Array<{
      name: string;
    }>
  >([]);

  const handleSelectedLivestock = (name: string) => {
    const isLivestockSelected = selectedLivestock.some(
      (tag) => tag.name === name
    );

    if (isLivestockSelected) {
      setSelectedLivestock((prevLivestock) =>
        prevLivestock.filter((tag) => tag.name !== name)
      );
    } else {
      setSelectedLivestock((prevLivestock) => [...prevLivestock, { name }]);
    }
  };

  const goBackStep1 = () => {
    setSteps(1);
  };

  const goBackStep2 = () => {
    setSteps(2);
  };

  const goBackStep3 = () => {
    setSteps(3);
  };

  const handleCaptureFarm = () => {
    setTimeout(() => {
      setIsCaptureModal(true);
    }, 200);
  };

  const SaveCapturedFarm = () => {
    setFarmLoader(true);
    // capture farm details
    dispatch(
      captureFarm({
        size: area.toFixed(2),
        id: localStorage.getItem("farmerId"),
        perimeter: perimeter.toFixed(2),
        state_id: selectedState?.id,
        lga_id: selectedLGA?.id,
        coordinates: positions,
        crops: selectedCrops?.map((crop: any) => crop?.name),
        livestock: selectedLivestock?.map((livestock) => livestock?.name),
      })
    ).then((res) => {
      if (res?.type === "captureFarm/fulfilled") {
        setFarmLoader(false);
        setShow(false);
        setIsCaptureModal(false);
        dispatch(
          fetchAgentFarmers({
            page: 1,
          })
        );
        setSteps(1);
        toast.success("Farmer information captured sucessfully!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
            width: "100%",
          },
          // position: "top-right",
        });
      } else if (res?.type === "captureFarm/rejected") {
        setFarmLoader(false);
      } else {
        setFarmLoader(false);
      }
    });
  };

  interface Values {
    phone_number: string;
    first_name: string;
    last_name: string;
    bvn: string;
    landmark: string;
    dob: string;
  }

  type FarmerInfoType = {
    phone_number: string;
    first_name: string;
    last_name: string;
    bvn: string;
    landmark?: string;
    dob: string;
    state_id: number;
    lga_id: number;
    gender: string;
    season?: string;
    crops?: string[];
    livestock?: string[];
    farming_season?: string;
  };
  const [farmerInfo, setFarmerInfo] = useState<FarmerInfoType>(
    {} as FarmerInfoType
  );

  const handleSubmit = (values: Values) => {
    // save all values here in an object
    let resp = {
      ...values,
      state_id: selectedState?.id,
      lga_id: selectedLGA?.id,
      gender: selectedGender?.tag,
    };

    setFarmerInfo(resp);
    setSteps(2);
  };

  const handleStep2 = () => {
    setSteps(3);
  };

  // interface FormData{
  //   first_name: string;
  // last_name: string;
  // state_id?: number;
  // lga_id?: number;
  // bvn?: string;
  // nin?: string;
  // phone_number: string;
  // date_of_birth?: string;
  // gender?: string;
  // farming_season?: string;
  // crops?: string[];
  // livestock?: string[];
  // }

  // const [formData] = useState<FormData>({
  //   first_name: '',
  //   last_name: '',
  //   state_id: 0,
  //   lga_id: 0,
  //   bvn: '',
  //   nin: '',
  //   phone_number: '',
  //   date_of_birth: '',
  //   gender: '',
  //   farming_season: '',
  //   crops: [],
  //   livestock?: []
  // });

  const handleStep3 = () => {
    // dispatch to register a farmer before capturing farm
    let resp = {
      ...farmerInfo,
      farming_season: selectedSeason?.tag,
      crops: selectedCrops.map((obj) => obj.name),
      livestock: selectedLivestock.map((obj) => obj.name),
    };
    setLoader(true);

    const formData = new FormData();
    formData.append("passport", passport);

    const identificationFormData = new FormData();
    identificationFormData.append(
      "identificationPhotograph",
      identificationPhotograph
    );

    // selectedCrops.forEach((name:any, index:any) => {
    //   formDataToSend.append(`crops[${index}]`, name);
    // });

    // selectedLivestock.forEach((name:any, index:any) => {
    //   formDataToSend.append(`livestock[${index}]`, name);
    // });

    const credentials = {
      first_name: resp.first_name,
      last_name: resp.last_name,
      state_id: resp?.state_id,
      lga_id: resp.lga_id,
      bvn: resp.bvn,
      phone_number: "+234" + resp.phone_number.substring(1),
      date_of_birth: resp.dob,
      gender: selectedGender?.name?.toLowerCase(),
      farming_season: selectedSeason?.tag,
      crops: selectedCrops.map((obj) => obj.name),
      livestock: selectedLivestock.map((obj) => obj.name),
      image: passport,
      kyc_type: selectedIdentification?.id,
      kyc_image: identificationPhotograph,
      kyc_number: identificationNo,
    };

    dispatch(registerSelfFarmers(credentials)).then((res) => {
      if (res?.type === "registerSelfFarmers/fulfilled") {
        toast.success(
          "Farmer details added sucessfully, Please proceed to capture your farm",
          {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
              width: "100%",
            },
            // position: "top-right",
          }
        );
        const farmer_ID = res?.payload?.data?.farmer_id;
        // setFarmerID(farmer_ID);
        localStorage.setItem("farmerId", farmer_ID);
        setLoader(false);
        setSteps(4);
      } else if (res?.type === "registerSelfFarmers/rejected") {
        setLoader(false);
      }
    });
  };

  // useEffect(() => {
  //   let farmerID = localStorage.getItem("farmerId");
  //   if (farmerID) {
  //     setSteps(4);
  //   }
  // }, []);

  // state for calculationg coordinates
  const [perimeter, setPerimeter] = useState(0);
  const [area, setArea] = useState(0);

  const [positions, setPositions] = useState<
    {
      lng: any;
      lat: any;
    }[]
  >([]);

  const handleMapPosition = (val: any) => {
    // point added
    toast.success("Point added!", {
      style: {
        border: "1px solid #2B8C34",
        backgroundColor: "#2B8C34",
        color: "#FFFFFF",
        fontSize: 14,
      },
      position: "top-right",
    });
    setPositions((prevPos) => [...prevPos, val]);
  };

  const submitMapPosition = () => {
    toast.success("Points captured successfully!", {
      style: {
        border: "1px solid #2B8C34",
        backgroundColor: "#2B8C34",
        color: "#FFFFFF",
        fontSize: 14,
      },
      position: "top-right",
    });
    setIsCaptureModal(false);
  };

  const calculateArea = (coordinates: any) => {
    const polygon = new window.google.maps.Polygon({
      paths: coordinates,
    });

    const area = window.google.maps.geometry.spherical.computeArea(
      polygon.getPath()
    );

    setArea(area);
  };

  const computeLength = (coordinates: any) => {
    const polygon = new window.google.maps.Polygon({
      paths: coordinates,
    });
    const length = window.google.maps.geometry.spherical.computeLength(
      polygon.getPath()
    );

    setPerimeter(length);
  };

  useEffect(() => {
    if (positions.length > 0) {
      computeLength(positions);
    }
  }, [positions]);

  useEffect(() => {
    if (positions.length > 0) {
      calculateArea(positions);
    }
  }, [positions]);

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.error("Error getting user location:", error);
          },
          { enableHighAccuracy: true, timeout: 5000 }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getUserLocation();

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error getting user location:", error);
      },
      { enableHighAccuracy: true, timeout: 5000 }
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const center = useMemo(() => {
    if (!positions || !positions.length) return { lat: 0, lng: 0 };

    const bounds = positions.reduce(
      (acc, coord) => {
        acc.minLat = Math.min(acc.minLat, coord.lat);
        acc.maxLat = Math.max(acc.maxLat, coord.lat);
        acc.minLng = Math.min(acc.minLng, coord.lng);
        acc.maxLng = Math.max(acc.maxLng, coord.lng);
        return acc;
      },
      {
        minLat: Infinity,
        maxLat: -Infinity,
        minLng: Infinity,
        maxLng: -Infinity,
      }
    );

    return {
      lat: (bounds.minLat + bounds.maxLat) / 2,
      lng: (bounds.minLng + bounds.maxLng) / 2,
    };
  }, [positions]);

  useEffect(() => {
    dispatch(retrieveStates());
    dispatch(retrieveCrops());
    dispatch(retrieveLivestock());
  }, [dispatch]);

  return (
    <>
      {/* Select Farmer Category Modal */}
      <ModalComponent
        title="Select Farm Category"
        subtitle="Select the farmer category you want to capture"
        isOpen={isCreateCat}
        onClose={onClose}
      >
        <div className="mt-5">
          {filterType.map((val) => (
            <>
              <div
                key={val.id}
                onClick={() => toggleFilterType(val)}
                className={
                  filterId === val.id
                    ? "bg-[#EDF7EE] border border-primary mb-4 rounded-[8px] py-4 px-4 cursor-pointer"
                    : "bg-[#F7F7F7] rounded-[8px] py-4 px-4 cursor-pointer mb-4"
                }
              >
                <p
                  className={
                    filterId === val.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#344335] text-sm font-semibold"
                  }
                >
                  {val.text}
                </p>
              </div>
            </>
          ))}
        </div>

        <div className="flex justify-end mt-5">
          <button
            disabled={selectedFilter === null}
            onClick={handleContinue}
            type="submit"
            className="bg-primary 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Continue
          </button>
        </div>
      </ModalComponent>

      {/* Add Farmer Data Modal */}
      <ModalComponent
        title="Add Farmer Data"
        subtitle=""
        isOpen={isShow}
        onClose={showModal}
        size="max-w-[950px]"
      >
        {/* ------------------ STEP 1 ----------------------------- */}
        {steps === 1 && (
          <div>
            {/* progress bar */}
            <div className="flex gap-1 mt-5">
              <div className="bg-[#2B8C34] rounded-[2px] w-full h-[4px]"></div>
              <div className="bg-[#ABB6AC] rounded-[2px] w-full"></div>
              <div className="bg-[#ABB6AC] rounded-[2px] w-full"></div>
              <div className="bg-[#ABB6AC] rounded-[2px] w-full"></div>
            </div>

            {/* form */}
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                phone_number: "",
                bvn: "",
                landmark: "",
                dob: "",
              }}
              validationSchema={registerFarmerValidator}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                handleSubmit(values);
              }}
            >
              {({
                handleChange,
                isSubmitting,
                isValid,
                dirty,
                handleSubmit,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="" onSubmit={handleSubmit}>
                  <div className="grid md:grid-cols-3 gap-4 mt-5">
                    <div>
                      <label
                        htmlFor="phone_number"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        Phone Number
                      </label>
                      <input
                        type="text"
                        name="phone_number"
                        placeholder="Phone Number"
                        className={
                          touched.phone_number && errors.phone_number
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.phone_number && errors.phone_number ? (
                        <div className="flex">
                          <img src={warning} className="" alt="warning" />
                          <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.phone_number && errors.phone_number}
                          </small>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        className={
                          touched.first_name && errors.first_name
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.first_name && errors.first_name ? (
                        <div className="flex">
                          <img src={warning} className="" alt="warning" />
                          <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.first_name && errors.first_name}
                          </small>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label
                        htmlFor="last_name"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        className={
                          touched.last_name && errors.last_name
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.last_name && errors.last_name ? (
                        <div className="flex">
                          <img src={warning} className="" alt="warning" />
                          <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.last_name && errors.last_name}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="grid md:grid-cols-3 gap-4 mt-5">
                    <div>
                      <label
                        htmlFor="dob"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        max={moment().format("YYYY-MM-DD")}
                        name="dob"
                        placeholder="Date of Birth"
                        className={
                          touched.dob && errors.dob
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.dob}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.dob && errors.dob ? (
                        <div className="flex">
                          <img src={warning} className="" alt="warning" />
                          <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.dob && errors.dob}
                          </small>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label
                        htmlFor="bvn"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        BVN
                      </label>
                      <input
                        type="text"
                        name="bvn"
                        placeholder="Input BVN"
                        className={
                          touched.bvn && errors.bvn
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.bvn}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.bvn && errors.bvn ? (
                        <div className="flex">
                          <img src={warning} className="" alt="warning" />
                          <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                            {touched.bvn && errors.bvn}
                          </small>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label
                        htmlFor="landmark"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        Landmark (Optional)
                      </label>
                      <input
                        type="text"
                        name="landmark"
                        placeholder="Landmark"
                        className={
                          touched.landmark && errors.landmark
                            ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                            : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        }
                        value={values.landmark}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="grid md:grid-cols-3 gap-4 mt-5">
                    <div>
                      <label
                        htmlFor="gender"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        Gender
                      </label>
                      <div className="relative">
                        <div
                          onClick={() => setIsToggleGender(!isToggleGender)}
                          className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                          style={
                            isToggleGender
                              ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                              : {}
                          }
                        >
                          <p
                            className={`${
                              selectedGender
                                ? "text-[#344335] text-sm"
                                : "text-[#96A397] text-sm"
                            }`}
                          >
                            {selectedGender?.name || "Select gender"}
                          </p>
                          {isToggleGender ? (
                            <ExpandLess
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          )}
                        </div>

                        {/*  SELECT OPTIONS */}
                        {isToggleGender && (
                          <div
                            className=" rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                            style={{
                              boxShadow:
                                "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                            }}
                          >
                            {/* gender */}
                            {gender.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() =>
                                    handleSelectedGender(
                                      val?.name,
                                      val?.id,
                                      val?.tag
                                    )
                                  }
                                  className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                                    index < gender.length - 1 ? "mb-1" : ""
                                  } ${
                                    selectedGender?.name === val?.name
                                      ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                      : "text-[#344335] bg-white"
                                  }`}
                                >
                                  <p className=" leading-5 text-sm font-medium">
                                    {val?.name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="firstname"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        Farmer's State
                      </label>
                      <div className="relative">
                        <div
                          onClick={() => setIsToggleState(!isToggleState)}
                          className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                          style={
                            isToggleState
                              ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                              : {}
                          }
                        >
                          <p
                            className={`${
                              selectedState
                                ? "text-[#344335] text-sm"
                                : "text-[#96A397] text-sm"
                            }`}
                          >
                            {selectedState?.name || "Select State"}
                          </p>
                          {isToggleState ? (
                            <ExpandLess
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          )}
                        </div>

                        {/*  SELECT OPTIONS */}
                        {isToggleState && (
                          <div
                            className=" rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                            style={{
                              boxShadow:
                                "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                            }}
                          >
                            {/* States */}
                            {states.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() =>
                                    handleSelectedState(val?.name, val?.id)
                                  }
                                  className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                                    index < states.length - 1 ? "mb-1" : ""
                                  } ${
                                    selectedState?.name === val?.name
                                      ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                      : "text-[#344335] bg-white"
                                  }`}
                                >
                                  <p className=" leading-5 text-sm font-medium">
                                    {val?.name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="lga"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        Farmer's Local Government
                      </label>
                      <div className="relative">
                        <div
                          onClick={() => setIsToggleLGA(!isToggleLGA)}
                          className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                          style={
                            isToggleLGA
                              ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                              : {}
                          }
                        >
                          <p
                            className={`${
                              selectedLGA
                                ? "text-[#344335] text-sm"
                                : "text-[#96A397] text-sm"
                            }`}
                          >
                            {selectedLGA?.name || "Select LGA"}
                          </p>
                          {isToggleLGA ? (
                            <ExpandLess
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          )}
                        </div>

                        {/*  SELECT OPTIONS */}
                        {isToggleLGA && (
                          <div
                            className=" rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                            style={{
                              boxShadow:
                                "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                            }}
                          >
                            {/* States */}
                            {lgas.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() =>
                                    handleSelectedLGA(val?.name, val?.id)
                                  }
                                  className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                                    index < lgas.length - 1 ? "mb-1" : ""
                                  } ${
                                    selectedLGA?.name === val?.name
                                      ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                      : "text-[#344335] bg-white"
                                  }`}
                                >
                                  <p className=" leading-5 text-sm font-medium">
                                    {val?.name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end gap-2 mt-6">
                    <button
                      type="submit"
                      disabled={!(isValid && dirty)}
                      className="bg-primary 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {/* ----------- STEP 2 --------------- */}

        {steps === 2 && (
          <div>
            <div
              className="flex items-center cursor-pointer mt-5"
              onClick={goBackStep1}
            >
              <img src={go_back} alt="go back" className="w-[20px] h-[20px]" />
              <p className="text-sm font-semibold text-[#5C715E] ml-[4px] ">
                Go Back
              </p>
            </div>

            {/* progress bar */}
            <div className="flex gap-1 mt-3">
              <div className="bg-[#2B8C34] rounded-[2px] w-full h-[4px]"></div>
              <div className="bg-[#2B8C34] rounded-[2px] w-full"></div>
              <div className="bg-[#ABB6AC] rounded-[2px] w-full"></div>
              <div className="bg-[#ABB6AC] rounded-[2px] w-full"></div>
            </div>

            <div className="mt-5">
              {/* PASSPORT */}
              <div className="mb-4">
                <p className="block mb-2 text-[#344335] text-sm font-medium">
                  Passport Photograph
                </p>
                <label htmlFor="file-upload">
                  {file ? (
                    <img
                      src={file}
                      alt="  Document"
                      className="h-[134px] max-w-full w-full border-[1px] border-[#A6A08C] bg-[#FFFFFF] rounded-[8px] object-cover"
                    />
                  ) : (
                    <div className="h-[134px] bg-[#ECEFEC]  cursor-pointer gap-2 flex flex-col items-center justify-center rounded-[8px] border-[1px] border-[#A6A08C]">
                      <div className="mb-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M18.6668 2.66675H8.00016C6.5335 2.66675 5.34683 3.86675 5.34683 5.33341L5.3335 26.6667C5.3335 28.1334 6.52016 29.3334 7.98683 29.3334H24.0002C25.4668 29.3334 26.6668 28.1334 26.6668 26.6667V10.6667L18.6668 2.66675ZM24.0002 26.6667H8.00016V5.33341H17.3335V12.0001H24.0002V26.6667ZM10.6668 20.0134L12.5468 21.8934L14.6668 19.7867V25.3334H17.3335V19.7867L19.4535 21.9067L21.3335 20.0134L16.0135 14.6667L10.6668 20.0134Z"
                            fill="#7C8D7E"
                          />
                        </svg>
                      </div>

                      <p className="text-sm font-[400] text-[#7C8D7E]  leading-[18px] cursor-pointer">
                        Upload your passport photograph
                      </p>
                    </div>
                  )}

                  <input
                    id="file-upload"
                    required
                    onChange={(event) => handleSetFile(event)}
                    type="file"
                    className=" w-full"
                  />
                </label>
              </div>

              <div className="mb-4">
                <div>
                  <label
                    htmlFor="gender"
                    className="block mb-2 text-[#344335] text-sm font-normal"
                  >
                    Means of Identification
                  </label>
                  <div className="relative" ref={identificationRef}>
                    <div
                      onClick={() =>
                        setIsToggleIdentification(!isToggleIdentification)
                      }
                      className={`flex cursor-pointer justify-between items-center w-full rounded-lg border font-medium border-[#96A397] bg-white py-3 px-4 text-[#344335] text-sm`}
                      style={
                        isToggleIdentification
                          ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                          : {}
                      }
                    >
                      <p
                        className={`${
                          selectedIdentification
                            ? "text-[#344335] text-sm"
                            : "text-[#96A397] text-sm"
                        }`}
                      >
                        {selectedIdentification?.name ||
                          "Select Identification"}
                      </p>
                      {isToggleIdentification ? (
                        <ExpandLess
                          style={{
                            fontSize: "20px",
                            color: "#344335",
                          }}
                        />
                      ) : (
                        <ExpandMore
                          style={{
                            fontSize: "20px",
                            color: "#344335",
                          }}
                        />
                      )}
                    </div>

                    {/*  SELECT OPTIONS */}
                    {isToggleIdentification && (
                      <div
                        className=" rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                        style={{
                          boxShadow:
                            "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {/* gender */}
                        {identification.map((val, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                handleSelectedIdentification(val?.name, val?.id)
                              }
                              className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                                index < gender.length - 1 ? "mb-1" : ""
                              } ${
                                selectedIdentification?.name === val?.name
                                  ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                  : "text-[#344335] bg-white"
                              }`}
                            >
                              <p className=" leading-5 text-sm font-medium">
                                {val?.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Identification Number */}
              <div className="mb-6">
                <div>
                  <label className="block mb-2 text-[#344335] text-sm font-normal">
                    {selectedIdentification?.name
                      ? `${selectedIdentification?.name} Number`
                      : "Identification Number"}
                  </label>
                  <input
                    type="text"
                    placeholder={
                      selectedIdentification?.name
                        ? `${selectedIdentification?.name} Number`
                        : "Identification Number"
                    }
                    className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                    onChange={(event) => handleIdNumber(event)}
                    value={identificationNo}
                  />
                </div>
              </div>

              {/* MEANS OF IDENTIFICATION */}
              <div>
                <p className="block mb-2 text-[#344335] text-sm font-medium">
                  {selectedIdentification?.name
                    ? `${selectedIdentification?.name} Photograph`
                    : "Means of Identification"}
                </p>
                <label htmlFor="uploadfile-upload">
                  {identificationFile ? (
                    <img
                      src={identificationFile}
                      alt="Document"
                      className="h-[134px] max-w-full w-full border-[1px] border-[#A6A08C] bg-[#FFFFFF] rounded-[8px] object-cover"
                    />
                  ) : (
                    <div className="h-[134px] bg-[#ECEFEC]  cursor-pointer gap-2 flex flex-col items-center justify-center rounded-[8px] border-[1px] border-[#A6A08C]">
                      <div className="mb-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M18.6668 2.66675H8.00016C6.5335 2.66675 5.34683 3.86675 5.34683 5.33341L5.3335 26.6667C5.3335 28.1334 6.52016 29.3334 7.98683 29.3334H24.0002C25.4668 29.3334 26.6668 28.1334 26.6668 26.6667V10.6667L18.6668 2.66675ZM24.0002 26.6667H8.00016V5.33341H17.3335V12.0001H24.0002V26.6667ZM10.6668 20.0134L12.5468 21.8934L14.6668 19.7867V25.3334H17.3335V19.7867L19.4535 21.9067L21.3335 20.0134L16.0135 14.6667L10.6668 20.0134Z"
                            fill="#7C8D7E"
                          />
                        </svg>
                      </div>

                      <p className="text-sm font-[400] text-[#7C8D7E]  leading-[18px] cursor-pointer">
                        Upload a copy of your identification
                      </p>
                    </div>
                  )}

                  <input
                    id="uploadfile-upload"
                    required
                    onChange={(event) => handleSetIdentificationFile(event)}
                    type="file"
                    className=" w-full"
                    // value={idNumber}
                  />
                </label>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-6">
              <button
                type="submit"
                onClick={goBackStep1}
                className="rounded-lg py-3 px-5 text-primary text-sm font-medium transition-all"
              >
                Back
              </button>
              <button
                type="submit"
                disabled={
                  file !== null &&
                  identificationFile !== null &&
                  identificationNo !== "" &&
                  selectedIdentification?.name !== ""
                    ? false
                    : true
                }
                onClick={handleStep2}
                className="bg-primary 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
              >
                Continue
              </button>
            </div>
          </div>
        )}

        {/* -------------------- STEP 3 ---------------------- */}
        {steps === 3 && (
          <div>
            <div
              className="flex items-center cursor-pointer mt-5"
              onClick={goBackStep2}
            >
              <img src={go_back} alt="go back" className="w-[20px] h-[20px]" />
              <p className="text-sm font-semibold text-[#5C715E] ml-[4px] ">
                Go Back
              </p>
            </div>

            {/* progress bar */}
            <div className="flex gap-1 mt-3">
              <div className="bg-[#2B8C34] rounded-[2px] w-full h-[4px]"></div>
              <div className="bg-[#2B8C34] rounded-[2px] w-full"></div>
              <div className="bg-[#2B8C34] rounded-[2px] w-full"></div>
              <div className="bg-[#ABB6AC] rounded-[2px] w-full"></div>
            </div>

            <div className="grid md:grid-cols-3 gap-4 mt-5">
              <div>
                <label
                  htmlFor="season"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Farmer Season
                </label>
                <div className="relative">
                  <div
                    onClick={() => setIsToggleSeason(!isToggleSeason)}
                    className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                    style={
                      isToggleSeason
                        ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                        : {}
                    }
                  >
                    <p
                      className={`${
                        selectedSeason
                          ? "text-[#344335] text-sm"
                          : "text-[#96A397] text-sm"
                      }`}
                    >
                      {selectedSeason?.name || "Select a season"}
                    </p>
                    {isToggleSeason ? (
                      <ExpandLess
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    ) : (
                      <ExpandMore
                        style={{ fontSize: "20px", color: "#344335" }}
                      />
                    )}
                  </div>

                  {/*  SELECT OPTIONS */}
                  {isToggleSeason && (
                    <div
                      className=" rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {/* season */}
                      {season.map((val, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelectedSeason(val?.name, val?.id, val?.tag)
                            }
                            className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                              index < season.length - 1 ? "mb-1" : ""
                            } ${
                              selectedSeason?.name === val?.name
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                          >
                            <p className=" leading-5 text-sm font-medium">
                              {val?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="user"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Crop
                </label>
                <div className="relative">
                  <div
                    className={`flex cursor-pointer  items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                    style={
                      isToggleCrops
                        ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                        : {}
                    }
                    onClick={() => setIsToggleCrops(!isToggleCrops)}
                  >
                    <div className="flex flex-wrap  gap-2 items-center w-[95%]">
                      {selectedCrops?.length !== 0 ? (
                        <>
                          {selectedCrops?.map((tag, index) => {
                            return (
                              <div
                                className="rounded-[4px] text-sm bg-[#DBFFDF] px-1 py-[2px] text-[#2B8C34] flex items-center gap-2"
                                key={index}
                              >
                                <p className="capitalize"> {tag?.name}</p>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        "Select Crop(s)"
                      )}
                    </div>

                    <div className=" w-[5%]">
                      {isToggleCrops ? (
                        <ExpandLess
                          style={{ fontSize: "20px", color: "#344335" }}
                        />
                      ) : (
                        <ExpandMore
                          style={{ fontSize: "20px", color: "#344335" }}
                        />
                      )}
                    </div>
                  </div>

                  {/*  SELECT OPTIONS */}
                  {isToggleCrops && (
                    <div
                      className="max-h-[300px] absolute py-2 overflow-scroll rounded-[18px] bg-white w-full mt-2 px-2"
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {crops?.map((crop, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => handleSelectedCrop(crop?.name)}
                            className={`px-4 py-3  flex gap-2  items-center text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] 
                            ${index < crops.length - 1 ? "mb-1" : ""}
                            ${
                              selectedCrops.some(
                                (selectedTag) => selectedTag.name === crop?.name
                              )
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                          >
                            <input
                              className="accent-[#2B8C34] w-[15px] h-[15px]"
                              type="checkbox"
                              id={crop?.name}
                              checked={selectedCrops.some(
                                (selectedTag) => selectedTag.name === crop?.name
                              )}
                            />
                            <p className="capitalize leading-5 text-sm font-medium">
                              {crop?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="user"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Livestock
                </label>
                <div className="relative">
                  <div
                    className={`flex cursor-pointer  items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                    style={
                      isToggleLivestock
                        ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                        : {}
                    }
                    onClick={() => setIsToggleLivestock(!isToggleLivestock)}
                  >
                    <div className="flex flex-wrap  gap-2 items-center w-[95%]">
                      {selectedLivestock?.length !== 0 ? (
                        <>
                          {selectedLivestock?.map((tag, index) => {
                            return (
                              <div
                                className="rounded-[4px] text-sm bg-[#DBFFDF] px-1 py-[2px] text-[#2B8C34] flex items-center gap-2"
                                key={index}
                              >
                                <p className="capitalize"> {tag?.name}</p>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        "Select Livestock(s)"
                      )}
                    </div>

                    <div className=" w-[5%]">
                      {isToggleLivestock ? (
                        <ExpandLess
                          style={{ fontSize: "20px", color: "#344335" }}
                        />
                      ) : (
                        <ExpandMore
                          style={{ fontSize: "20px", color: "#344335" }}
                        />
                      )}
                    </div>
                  </div>

                  {/*  SELECT OPTIONS */}
                  {isToggleLivestock && (
                    <div
                      className="max-h-[300px] absolute py-2 overflow-scroll rounded-[18px] bg-white w-full mt-2 px-2"
                      style={{
                        boxShadow:
                          "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      {livestock?.map((val, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => handleSelectedLivestock(val?.name)}
                            className={`px-4 py-3  flex gap-2  items-center text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] 
                            ${index < livestock.length - 1 ? "mb-1" : ""}
                            ${
                              selectedLivestock.some(
                                (selectedTag) => selectedTag.name === val?.name
                              )
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                          >
                            <input
                              className="accent-[#2B8C34] w-[15px] h-[15px]"
                              type="checkbox"
                              id={val?.name}
                              checked={selectedLivestock.some(
                                (selectedTag) => selectedTag.name === val?.name
                              )}
                            />
                            <p className="capitalize leading-5 text-sm font-medium">
                              {val?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-6">
              <button
                type="submit"
                onClick={goBackStep2}
                className="rounded-lg py-3 px-5 text-primary text-sm font-medium transition-all"
              >
                Back
              </button>
              <button
                type="submit"
                disabled={
                  (selectedCrops?.length !== 0 ||
                    selectedLivestock?.length !== 0) &&
                  selectedSeason?.name !== "" &&
                  loader === false
                    ? false
                    : true
                }
                onClick={handleStep3}
                className="bg-primary 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
              >
                Continue
              </button>
            </div>
          </div>
        )}

        {/* ----- STEP 4 --------------------- */}
        {steps === 4 && (
          <div>
            <div
              className="flex items-center cursor-pointer mt-5"
              onClick={goBackStep3}
            >
              <img src={go_back} alt="go back" className="w-[20px] h-[20px]" />
              <p className="text-sm font-semibold text-[#5C715E] ml-[4px] ">
                Go Back
              </p>
            </div>
            {/* progress bar */}
            <div className="flex gap-1 mt-3">
              <div className="bg-[#2B8C34] rounded-[2px] w-full h-[4px]"></div>
              <div className="bg-[#2B8C34] rounded-[2px] w-full"></div>
              <div className="bg-[#2B8C34] rounded-[2px] w-full"></div>
              <div className="bg-[#2B8C34] rounded-[2px] w-full"></div>
            </div>
            <div className="grid md:grid-cols-3 gap-4 mt-5">
              <div>
                <label
                  htmlFor="season"
                  className="block mb-2 text-[#344335] text-sm font-normal"
                >
                  Capture Farm Boundary
                </label>
                {positions.length > 0 ? (
                  <div className="w-full h-[150px]">
                    <APIProvider apiKey={API_KEY}>
                      <div className="h-[150px] rounded-[4px] w-full">
                        <Map
                          mapId="d10801052e62b784"
                          disableDefaultUI={true}
                          mapTypeId="satellite"
                          gestureHandling={"greedy"}
                          zoom={19}
                          center={center}
                        >
                          <Polygon
                            strokeWeight={3}
                            paths={positions}
                            strokeColor={"#FFC508"}
                            strokeOpacity={1}
                            fillColor={"#FFFFFF66"}
                            fillOpacity={0.7}
                          />
                        </Map>
                      </div>
                    </APIProvider>
                  </div>
                ) : (
                  <div
                    onClick={handleCaptureFarm}
                    className="border border-[#A6A08C] rounded-[8px] flex flex-col min-h-[150px] justify-center items-center bg-[#ECEFEC] cursor-pointer"
                  >
                    <FormatShapes
                      style={{ fontSize: "30px", color: "#7C8D7E" }}
                    />
                    <p className="text-[#7C8D7E] text-sm font-medium mt-1">
                      Capture farm boundaries
                    </p>
                  </div>
                )}
              </div>
              <div className="md:col-span-2">
                <div className="grid md:grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="firstname"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Farm State
                    </label>
                    <div className="relative">
                      <div
                        onClick={() => setIsToggleState(!isToggleState)}
                        className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                        style={
                          isToggleState
                            ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                            : {}
                        }
                      >
                        <p
                          className={`${
                            selectedState
                              ? "text-[#344335] text-sm"
                              : "text-[#96A397] text-sm"
                          }`}
                        >
                          {selectedState?.name || "Select State"}
                        </p>
                        {isToggleState ? (
                          <ExpandLess
                            style={{ fontSize: "20px", color: "#344335" }}
                          />
                        ) : (
                          <ExpandMore
                            style={{ fontSize: "20px", color: "#344335" }}
                          />
                        )}
                      </div>

                      {/*  SELECT OPTIONS */}
                      {isToggleState && (
                        <div
                          className=" rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll z-[9]"
                          style={{
                            boxShadow:
                              "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          {/* States */}
                          {states.map((val, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() =>
                                  handleSelectedState(val?.name, val?.id)
                                }
                                className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                                  index < states.length - 1 ? "mb-1" : ""
                                } ${
                                  selectedState?.name === val?.name
                                    ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                    : "text-[#344335] bg-white"
                                }`}
                              >
                                <p className=" leading-5 text-sm font-medium">
                                  {val?.name}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="lga"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        Farm Local Govt.
                      </label>
                      <div className="relative">
                        <div
                          onClick={() => setIsToggleLGA(!isToggleLGA)}
                          className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                          style={
                            isToggleLGA
                              ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                              : {}
                          }
                        >
                          <p
                            className={`${
                              selectedLGA
                                ? "text-[#344335] text-sm"
                                : "text-[#96A397] text-sm"
                            }`}
                          >
                            {selectedLGA?.name || "Select LGA"}
                          </p>
                          {isToggleLGA ? (
                            <ExpandLess
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          ) : (
                            <ExpandMore
                              style={{ fontSize: "20px", color: "#344335" }}
                            />
                          )}
                        </div>

                        {/*  SELECT OPTIONS */}
                        {isToggleLGA && (
                          <div
                            className=" rounded-[18px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                            style={{
                              boxShadow:
                                "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                            }}
                          >
                            {/* States */}
                            {lgas.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() =>
                                    handleSelectedLGA(val?.name, val?.id)
                                  }
                                  className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                                    index < lgas.length - 1 ? "mb-1" : ""
                                  } ${
                                    selectedLGA?.name === val?.name
                                      ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                      : "text-[#344335] bg-white"
                                  }`}
                                >
                                  <p className=" leading-5 text-sm font-medium">
                                    {val?.name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="user"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                        Farm Crop
                      </label>
                      <div className="relative">
                        <div
                          className={`flex cursor-pointer  items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
                          style={
                            isToggleCrops
                              ? { boxShadow: "0px 0px 0px 2px #D5E8D6" }
                              : {}
                          }
                          onClick={() => setIsToggleCrops(!isToggleCrops)}
                        >
                          <div className="flex flex-wrap  gap-2 items-center w-[80%]">
                            {selectedCrops?.length !== 0 ? (
                              <>
                                {selectedCrops?.map((tag, index) => {
                                  return (
                                    <div
                                      className="rounded-[4px] text-sm bg-[#DBFFDF] px-1 py-[2px] text-[#2B8C34] flex items-center gap-2"
                                      key={index}
                                    >
                                      <p className="capitalize"> {tag?.name}</p>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              "Crop(s)"
                            )}
                          </div>

                          <div className=" w-[20%]">
                            {isToggleCrops ? (
                              <ExpandLess
                                style={{ fontSize: "20px", color: "#344335" }}
                              />
                            ) : (
                              <ExpandMore
                                style={{ fontSize: "20px", color: "#344335" }}
                              />
                            )}
                          </div>
                        </div>

                        {/*  SELECT OPTIONS */}
                        {isToggleCrops && (
                          <div
                            className="max-h-[300px] absolute py-2 overflow-scroll rounded-[18px] bg-white w-full mt-2 px-2"
                            style={{
                              boxShadow:
                                "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                            }}
                          >
                            {crops?.map((crop, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => handleSelectedCrop(crop?.name)}
                                  className={`px-4 py-3  flex gap-2  items-center text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] 
                            ${index < crops.length - 1 ? "mb-1" : ""}
                            ${
                              selectedCrops.some(
                                (selectedTag) => selectedTag.name === crop?.name
                              )
                                ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                                : "text-[#344335] bg-white"
                            }`}
                                >
                                  <input
                                    className="accent-[#2B8C34] w-[15px] h-[15px]"
                                    type="checkbox"
                                    id={crop?.name}
                                    checked={selectedCrops.some(
                                      (selectedTag) =>
                                        selectedTag.name === crop?.name
                                    )}
                                  />
                                  <p className="capitalize leading-5 text-sm font-medium">
                                    {crop?.name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 gap-4 mt-5">
                  <div>
                    <label
                      htmlFor="livestock"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Farm Size
                    </label>
                    <input
                      type="text"
                      name="livestock"
                      placeholder="12,490 Ha"
                      value={`${area.toFixed(2)} m2`}
                      disabled
                      className="appearance-none w-full disabled:bg-[#F7F7F7] disabled:border-[#ABB6AC] disabled:text-[#ABB6AC] placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="coordinate"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Location Coordinate
                    </label>
                    <input
                      type="text"
                      name="coordinate"
                      placeholder="Coordinate"
                      value={`${userLocation.lat}, ${userLocation.lng}`}
                      disabled
                      className="appearance-none w-full disabled:bg-[#F7F7F7] disabled:border-[#ABB6AC] disabled:text-[#ABB6AC] placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-6">
              <button
                type="submit"
                className="rounded-lg py-3 px-5 text-primary text-sm font-medium transition-all"
              >
                Save for later
              </button>
              <button
                type="submit"
                disabled={positions.length === 0 || farmloader}
                onClick={SaveCapturedFarm}
                className="bg-primary 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </ModalComponent>

      {/* Farm Boundary Capture Modal */}
      <ModalComponent
        title="Farm Boundary Capture"
        subtitle=""
        isOpen={isCaptureModal}
        onClose={showCaptureModal}
        size="max-w-[950px]"
      >
        <div>
          {/* <div className='mt-4'>
                  <img src={capture_img} alt="capture" className='w-full' />
                </div> */}

          <div className="w-full mt-4 min-h-[500px] relative">
            <APIProvider apiKey={API_KEY}>
              <div className="h-[500px] rounded-[4px] w-full">
                {/* <Map 
                        zoom={20}
                       center={userLocation}
                        mapId="d10801052e62b784"   
                        gestureHandling={"greedy"}
                        disableDefaultUI={true}
                        mapTypeId="satellite" fullscreenControl={true}  >
                        <AdvancedMarker position={userLocation}>
                            <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
                        </AdvancedMarker> 
                        
                    </Map> */}
                <Map
                  mapId="d10801052e62b784"
                  disableDefaultUI={true}
                  mapTypeId="satellite"
                  gestureHandling={"greedy"}
                  {...cameraProps}
                  onCameraChanged={handleCameraChange}
                >
                  {infowindowOpen && (
                    <InfoWindow
                      maxWidth={200}
                      anchor={marker}
                      onCloseClick={() => setInfowindowOpen(false)}
                    >
                      To start farm measurement, move to your starting point and
                      tap the “Add starting point” button to mark your starting
                      point
                    </InfoWindow>
                  )}
                  <Marker
                    ref={markerRef}
                    position={userLocation}
                    onClick={() => setInfowindowOpen(true)}
                  />
                  <Polygon
                    strokeWeight={3}
                    paths={positions}
                    strokeColor={"#FFC508"}
                    strokeOpacity={1}
                    fillColor={"#FFFFFF66"}
                    fillOpacity={0.7}
                  />
                  <Circle
                    radius={radius}
                    center={userLocation}
                    onRadiusChanged={setRadius}
                    onCenterChanged={changeCenter}
                    strokeColor={"#0c4cb3"}
                    strokeOpacity={1}
                    strokeWeight={2}
                    fillColor={"#3b82f6"}
                    fillOpacity={0.3}
                    editable
                    draggable
                  />
                  <AdvancedMarker position={userLocation}>
                    <Pin
                      background={"#FBBC04"}
                      glyphColor={"#000"}
                      borderColor={"#000"}
                    />
                  </AdvancedMarker>
                </Map>

                <div className="bg-white rounded-[8px] py-4 px-4 min-w-full md:min-w-[200px] absolute bottom-6 left-1/2 transform -translate-x-1/2">
                  <p className="text-[#7C8D7E] text-xs font-medium">
                    Distance:{" "}
                    <span className="text-[#5C715E] text-xs font-semibold">
                      {perimeter.toFixed(2)} m
                    </span>
                  </p>
                  <div className="flex gap-1 items-center">
                    <p className="text-[#7C8D7E] text-xs font-medium">
                      Lat:{" "}
                      <span className="text-[#5C715E] text-xs font-semibold">
                        {userLocation.lat}
                      </span>
                    </p>
                    <p className="text-[#7C8D7E] text-xs font-medium">
                      Lng:{" "}
                      <span className="text-[#5C715E] text-xs font-semibold">
                        {userLocation.lng}
                      </span>
                    </p>
                  </div>

                  <button
                    onClick={() => handleMapPosition(userLocation)}
                    type="submit"
                    className="bg-primary mt-3
                                    disabled:bg-[#ABB6AC] flex gap-2 items-center w-full justify-center
                                    rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                  >
                    <img
                      src={add_circle}
                      className="w-[15px] h-[15px]"
                      alt="add icon"
                    />
                    {positions.length > 0 ? "Add point" : "Add starting point"}
                  </button>
                  {positions.length > 3 && (
                    <button
                      onClick={() => submitMapPosition()}
                      type="submit"
                      className="bg-transparent mt-2 border border-primary
                                    disabled:bg-[#ABB6AC] flex gap-2 items-center w-full justify-center
                                    rounded-lg py-3 px-5 text-primary text-sm font-medium hover:bg-[#24752B] hover:text-white transition-all"
                    >
                      Submit Point
                    </button>
                  )}
                </div>
              </div>
            </APIProvider>
          </div>

          <div className="flex mt-4 justify-between items-center">
            <button
              type="submit"
              className="rounded-lg py-3 text-primary text-sm font-medium transition-all"
            >
              Back
            </button>
            <div className="flex gap-2">
              <div className="w-[8px] h-[8px] bg-primary rounded-full"></div>
              <div className="w-[8px] h-[8px] bg-[#ECEEEC] rounded-full"></div>
              <div className="w-[8px] h-[8px] bg-[#ECEEEC] rounded-full"></div>
              <div className="w-[8px] h-[8px] bg-[#ECEEEC] rounded-full"></div>
            </div>
            <button
              type="submit"
              className="bg-primary 
                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
            >
              Next
            </button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default DataCapturePage;

import React,{useState} from 'react'
import { Add, ContentPaste } from "@mui/icons-material";


type InfoType = {
    id?: string | undefined;
  };

const AgentFarmReportPage = ({ id }: InfoType) => {

    const [typeID, setTypeID] = useState(1);
    const reportTypes = [
        {
          id: 1,
          title: "Overview",
        },
        {
          id: 2,
          title: "Crop Yield",
        },
        {
          id: 3,
          title: "Income Statement",
        },
        {
            id: 4,
            title: "Other Report",
          },
      ];
    
      const toggleType = (id: number) => {
        setTypeID(id);
      }

    return ( 
        <>
            <div className='border flex justify-between items-center border-[#ECEFEC] bg-[#FFFFFF] rounded-lg py-3 px-4'>
                 <ul className="flex gap-5 items-center">
                    {reportTypes.map((item) => (
                    <li
                        key={item.id}
                        onClick={() => toggleType(item.id)}
                        className={
                        typeID === item.id
                            ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer relative"
                            : "flex gap-1 items-center pb-1 cursor-pointer relative"
                        }
                    >
                        <p
                        className={
                            typeID === item.id
                            ? "text-primary text-sm font-semibold"
                            : "text-[#96A397] text-sm font-semibold"
                        }
                        >
                        {item.title}
                        </p>
                    </li>
                    ))}
                </ul>
                <div>
                    <button
                    type="submit"
                        className="bg-[#EDF7EE] 
                                         flex justify-center items-center gap-2
                                        rounded-lg px-5 py-3 text-primary text-sm font-medium transition-all"
                    >
                        <Add style={{ color: "#2B8C34", fontSize: "16px" }} />
                        Add Report
                    </button>
                </div>

            </div>

            {/* empty state */}
            <div className='flex flex-col justify-center items-center min-h-[500px]'>
           
                <ContentPaste style={{ color: "#7C8D7E", fontSize: "24px" }} />
        
            <p className="text-[#5C715E] text-sm font-semibold mt-2">No Report</p>
            <div className="mt-2 max-w-xs text-center">
                <p className="text-[#5C715E] text-sm font-medium">Please fill the reports to see<br/> more data</p>
            </div>
            <button
                    type="submit"
                  
                    className="bg-primary mt-5 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                    >
                        Create Report
                    </button>
             </div>
        </>
     );
}
 
export default AgentFarmReportPage;
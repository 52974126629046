import * as Yup from "yup";

export const loginValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const tokenValidator = Yup.object({
  token: Yup.string().required("Token is required"),
});

export const registerFarmerValidator = Yup.object({
  phone_number: Yup.string().required("Phone Number is required"),
  last_name: Yup.string().required("First Name is required"),
  first_name: Yup.string().required("Last Name is required"),
  dob: Yup.string().required("Date of Birth is required"),
  landmark: Yup.string(),
  bvn:  Yup.string()
  .min(11, 'BVN number cannot be less than 11 digits')
  .max(11, 'Exceeded characters for BVN Number')
  .required("BVN is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid BVN Number")
});

import React, { useEffect, useState } from "react";
import { ContentCopy, ChevronRight, ExpandMore } from "@mui/icons-material";
import passport_icon from "../../../../assets/icons/passport_icon.svg";
import government_icon from "../../../../assets/icons/government_icon.svg";
import voters_icon from "../../../../assets/icons/voters_icon.svg";
import driverlicense from "../../../../assets/icons/driverlicense.svg";
import interpassport from "../../../../assets/icons/interpassport.svg";
import { StackedAreaChart } from "../../../../components/AreaChart/StackedAreaChart";
import Bar from "../../../../components/BarChart/BarChart";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  fetchAgentFarmer,
  fetchReportsTypes,
} from "../../../../redux/Farmers/farmerActions";
import moment from "moment";
import ImageZoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  Verified,
  Active,
  Unverified,
  Fraudulent,
  Incomplete,
} from "../../../../components/Ranks/Rank";
import { toast } from "react-hot-toast";
import {
  VerifiedDescription,
  ActiveDescription,
  UnverifiedDescription,
  IncompleteDescription,
  FraudulentDescription,
} from "../../../../components/Ranks/RankDescription";
import ModalComponent from "../../../../components/Modals/modal";

type InfoType = {
  id?: string | undefined;
  phoneNumber: string | undefined;
  onSelect: (farmer: any) => void;
};
const AdminFarmerInformationPage = ({
  id,
  phoneNumber,
  onSelect,
}: InfoType) => {
  const dispatch = useAppDispatch();
  const { farmer } = useAppSelector((state) => state.farmer);
  const [show, setShow] = useState(false);

  const handleCopyNumber = (number: string) => {
    navigator.clipboard.writeText(number);
    toast.success(`${number} copied successfully`);
  };

  const [income, setIncome] = useState<any>({});
  const [cropYield, setCropYield] = useState<any>({});

  useEffect(() => {
    dispatch(fetchAgentFarmer({ phone_number: phoneNumber })).then((res) => {
      if (res?.type === "fetchAgentFarmer/fulfilled") {
        sessionStorage.setItem("farmer", JSON.stringify(res?.payload?.data));
        onSelect(res?.payload?.data);
      }
    });
    dispatch(fetchReportsTypes({ id: id, type: "income" })).then((res) => {
      if (res?.type === "fetchReportsTypes/fulfilled") {
        setIncome(res?.payload?.data);
      }
    });
    dispatch(fetchReportsTypes({ id: id, type: "crop_yield" })).then((res) => {
      if (res?.type === "fetchReportsTypes/fulfilled") {
        setCropYield(res?.payload?.data);
      }
    });
  }, [dispatch, phoneNumber, id, onSelect]);

  return (
    <>
      <div className="bg-white rounded-[8px] px-4 py-4 border border-[#ECEFEC]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px] mb-[24px]">
            {/* {sessionStorage.setItem("phone_number", phone_number)} */}
            {/* FARMER"S INITIALS */}

            {farmer?.farmer_tag === "likely fraudulent" ? (
              <Fraudulent>
                {" "}
                {farmer?.first_name.charAt(0)}
                {farmer?.last_name.charAt(0)}
              </Fraudulent>
            ) : farmer?.farmer_tag === "unverified" ? (
              <Unverified>
                {" "}
                {farmer?.first_name.charAt(0)}
                {farmer?.last_name.charAt(0)}
              </Unverified>
            ) : farmer?.farmer_tag === "incomplete" ? (
              <Incomplete>
                {" "}
                {farmer?.first_name.charAt(0)}
                {farmer?.last_name.charAt(0)}
              </Incomplete>
            ) : farmer?.farmer_tag === "verified" ? (
              <Verified>
                {" "}
                {farmer?.first_name.charAt(0)}
                {farmer?.last_name.charAt(0)}
              </Verified>
            ) : farmer?.farmer_tag === "active" ? (
              <Active>
                {" "}
                {farmer?.first_name.charAt(0)}
                {farmer?.last_name.charAt(0)}
              </Active>
            ) : (
              <Unverified>
                {" "}
                {farmer?.first_name.charAt(0)}
                {farmer?.last_name.charAt(0)}
              </Unverified>
            )}

            <div className="">
              <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                {farmer?.first_name} {farmer?.last_name}
              </p>
              <div
                className="flex gap-[6px] mt-[4px] cursor-pointer"
                onClick={() => handleCopyNumber(farmer?.phone_number)}
              >
                <p
                  className={`  ${
                    farmer?.farmer_tag === "likely fraudulent"
                      ? "text-[#fff]"
                      : farmer?.farmer_tag === "unverified"
                      ? "text-[#B92020]"
                      : farmer?.farmer_tag === "incomplete"
                      ? "text-[#FFC508]"
                      : farmer?.farmer_tag === "verified"
                      ? "text-[#2B8C34]"
                      : farmer?.farmer_tag === "active"
                      ? "text-[#fff]"
                      : "text-[#B92020]"
                  }font-semibold leading-[18px] text-sm`}
                >
                  {farmer?.phone_number}
                </p>
                <ContentCopy
                  className={`  ${
                    farmer?.farmer_tag === "likely fraudulent"
                      ? "text-[#fff]"
                      : farmer?.farmer_tag === "unverified"
                      ? "text-[#B92020]"
                      : farmer?.farmer_tag === "incomplete"
                      ? "text-[#FFC508]"
                      : farmer?.farmer_tag === "verified"
                      ? "text-[#2B8C34]"
                      : farmer?.farmer_tag === "active"
                      ? "text-[#fff]"
                      : "text-[#B92020]"
                  }`}
                  style={{ fontSize: "18px" }}
                />
              </div>
            </div>
          </div>

          <div>
            {farmer?.farmer_tag === "incomplete" ? (
              <IncompleteDescription handleClick={() => setShow(true)} />
            ) : farmer?.farmer_tag === "unverified" ? (
              <UnverifiedDescription handleClick={() => setShow(true)} />
            ) : farmer?.farmer_tag === "likely fraudulent" ? (
              <FraudulentDescription handleClick={() => setShow(true)} />
            ) : farmer?.farmer_tag === "verified" ? (
              <VerifiedDescription handleClick={() => setShow(true)} />
            ) : farmer?.farmer_tag === "active" ? (
              <ActiveDescription handleClick={() => setShow(true)} />
            ) : (
              <UnverifiedDescription handleClick={() => setShow(true)} />
            )}
          </div>
        </div>

        <div className="flex mt-2">
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Sex</p>
            <p className="mt-1 font-medium text-sm text-[#344335] capitalize">
              {" "}
              {farmer?.gender || "Nill"}
            </p>
          </div>
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Cooperative</p>
            <p className="mt-1 font-medium text-sm text-[#344335]"> {"Nill"}</p>
          </div>
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Registration Date</p>
            <p className="mt-1 font-medium text-sm text-[#344335]">
              {moment(farmer?.created).format("lll")}
            </p>
          </div>
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Registration Status</p>
            <div
              className={`${
                farmer?.is_complete
                  ? "border-primary text-primary "
                  : "border-[#B92020] text-[#B92020] "
              } mt-1 flex border px-1  rounded-[8px] font-medium text-xs w-fit`}
            >
              {farmer?.is_complete ? "Complete" : "Incomplete"}
            </div>
          </div>
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Farmer Tag</p>
            <div
              style={{
                paddingTop: "0px",
                paddingBottom: "0px",
                paddingLeft: "4px",
                paddingRight: "4px",
                fontSize: "12px",
              }}
              className={`capitalize mt-1  ${
                farmer?.farmer_tag === "complete" ||
                farmer?.farmer_tag === "verified" ||
                farmer?.farmer_tag === "active"
                  ? "success"
                  : farmer?.farmer_tag === "incomplete" ||
                    farmer?.farmer_tag === "unverified"
                  ? "failure"
                  : farmer?.farmer_tag === "likely fraudulent"
                  ? "error"
                  : "na"
              } rounded-[16px] flex items-center justify-center px-2 py-1 w-fit `}
            >
              <p className="text-xs font-medium leading-[18px]">
                {farmer?.farmer_tag || "Nill"}
              </p>
            </div>
            {/* <div className="mt-1 flex border border-[#B92020] px-1  text-[#B92020] rounded-[8px] font-medium text-xs w-fit">
              Inactive
            </div> */}
          </div>
        </div>

        <div className="flex mt-5">
          <div className="flex-1">
            <div className="flex gap-1 items-center">
              <p className="text-sm text-[#7C8D7E]">BVN</p>

              <div
                className={`${
                  farmer?.bvn_verified
                    ? "border-primary text-primary "
                    : "border-[#B92020] text-[#B92020] "
                }  flex border px-1  rounded-[8px] font-medium text-xs w-fit`}
              >
                {farmer?.bvn_verified ? "Verified" : "Not Verified"}
              </div>
            </div>

            <p className="mt-1 font-medium text-sm text-[#344335]">
              {farmer?.kyc[0]?.kyc_number
                ? farmer?.kyc[0]?.kyc_number?.charAt(0) +
                  "*" +
                  "*" +
                  "*" +
                  "*" +
                  "*" +
                  "*" +
                  "*" +
                  farmer?.kyc[0]?.kyc_number?.charAt(
                    farmer?.kyc[0]?.kyc_number?.length - 3
                  ) +
                  farmer?.kyc[0]?.kyc_number?.charAt(
                    farmer?.kyc[0]?.kyc_number?.length - 2
                  ) +
                  farmer?.kyc[0]?.kyc_number?.charAt(
                    farmer?.kyc[0]?.kyc_number?.length - 1
                  )
                : "Nil"}
            </p>
          </div>
          <div className="flex-1">
            <div className="flex gap-1 items-center">
              <p className="text-sm text-[#7C8D7E]">NIN</p>

              <div
                className={`${
                  farmer?.nin_verified
                    ? "border-primary text-primary "
                    : "border-[#B92020] text-[#B92020] "
                }  flex border px-1  rounded-[8px] font-medium text-xs w-fit`}
              >
                {farmer?.nin_verified ? "Verified" : "Not Verified"}
              </div>
            </div>
            <p className="mt-1 font-medium text-sm text-[#344335]">
              {farmer?.nin || "Nill"}
            </p>
          </div>
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Account Number</p>
            <button className="mt-1 flex bg-primary px-2 py-1 text-white rounded-[4px] font-medium text-xs w-fit">
              Generate Acct. No
            </button>
          </div>
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Bank</p>
            <p className="mt-1 font-medium text-sm text-[#344335]">Nil</p>
          </div>
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">State</p>
            <p className="mt-1 font-medium text-sm text-[#344335]">
              {" "}
              {farmer?.state || "Nil"}
            </p>
          </div>
        </div>

        <div className="flex mt-5">
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">LGA</p>
            <p className="mt-1 font-medium text-sm text-[#344335]">
              {farmer?.lga || "Nil"}
            </p>
          </div>
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Crop</p>
            <div className="flex capitalize flex-wrap pr-2">
              {farmer?.crops?.length === 0
                ? "Nill"
                : farmer?.crops
                    .map((item, index) =>
                      index === farmer.crops.length - 1 ? item : `${item}, `
                    )
                    .join("")}
            </div>
          </div>

          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Livestock</p>
            <div className="flex capitalize flex-wrap pr-2">
              {farmer?.livestock?.length === 0
                ? "Nill"
                : farmer?.livestock
                    .map((item, index) =>
                      index === farmer.livestock.length - 1 ? item : `${item}, `
                    )
                    .join("")}
            </div>
          </div>
          {/* <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Land Size</p>
            <p className="mt-1 font-medium text-sm text-[#344335]">Nil</p>
          </div> */}
          <div className="flex-1">
            <p className="text-sm text-[#7C8D7E]">Current Age</p>
            <p className="mt-1 font-medium text-sm text-[#344335]">
              {" "}
              {farmer?.age || "0"}
            </p>
          </div>

          <div className="flex-1"></div>
        </div>
      </div>

      {/* CHARTS */}
      <div className="mt-4 grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <div className="bg-white rounded-[8px] py-4 px-4  border border-[#ECEFEC]">
            <p className="text-sm text-[#5C715E] font-semibold pb-3">
              Average Income
            </p>
            <p className="text-[30px] text-[#344335] font-semibold leading-[36px] pb-4">
              {income?.avg_income ? (
                <>
                  ₦
                  {parseFloat(income?.avg_income).toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </>
              ) : (
                "0.00"
              )}
            </p>
            {/* AREACHART */}
            <div className="">
              <div className="mb-3">
                <div className="flex justify-end  items-center gap-6">
                  <div className="flex gap-2 items-center">
                    <div className="rounded-full w-2 h-2 bg-[#2B8C34]"></div>
                    <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E]">
                      Farmer Income
                    </p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="rounded-full w-2 h-2 bg-[#FFC508]"></div>
                    <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E]">
                      Average Per Income
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <StackedAreaChart data={income?.income_reports} height={180} />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-[8px] py-4 px-4 mt-4  border border-[#ECEFEC]">
            <div className="flex justify-between">
              <div>
                <p className="text-sm text-[#344335] font-semibold pb-3">
                  Crop Yield
                </p>
                <p className="text-[30px] text-[#344335] font-semibold leading-[36px] pb-3">
                  {cropYield?.avg_crop_yield ? (
                    <>
                      {parseFloat(cropYield?.avg_crop_yield).toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      ) ||
                        "0.00" ||
                        0}{" "}
                      {Number(cropYield?.avg_crop_yield) <= 1
                        ? "Tonne"
                        : "Tonnes"}
                    </>
                  ) : (
                    "0.00"
                  )}
                </p>
              </div>

              <div className="flex border-[#ECEFEC] border-[1px] rounded-lg px-[10px] py-[11px] items-center gap-1 h-fit  ">
                <div className="flex items-center cursor-pointer">
                  <p className="text-[#344335] text-sm font-medium leading-[18px]">
                    Rice
                  </p>
                  <ExpandMore style={{ color: "#96A397", fontSize: "16px" }} />
                </div>
              </div>
            </div>

            {/* AREACHART */}
            <div className="">
              <div className="mb-3 ">
                <div className="flex justify-end  items-center gap-6">
                  <div className="flex gap-2 items-center">
                    <div className="rounded-full w-2 h-2 bg-[#2B8C34]"></div>
                    <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E]">
                      Farmer Crop Yield
                    </p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="rounded-full w-2 h-2 bg-[#FFC508]"></div>
                    <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E]">
                      Average Peer Crop Yield
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <Bar
                  height={180}
                  data={cropYield?.crop_yield_reports}
                  size={12}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-[8px] py-4 px-4  border border-[#ECEFEC] h-fit">
          <p className="text-sm text-[#344335] font-semibold">Activity Log</p>

          <div className="mt-5">
            <div className="flex gap-2 items-center">
              <div className="w-[20px] h-[20px] rounded-full bg-primary text-[10px] font-medium text-white flex justify-center items-center">
                CA
              </div>
              <div>
                <p className="text-[#344335] font-semibold text-sm">
                  Celina Avong
                </p>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-xs text-[#7C8D7E] font-semibold">
                Edited <span className="text-[#344335]">Farmer Profile</span>
              </p>
            </div>
            <div className="mt-1">
              <p className="text-xs text-[#344335] font-medium">
                12:32 AM | 12 Aug 22
              </p>
            </div>
          </div>

          <div className="mt-5">
            <div className="flex gap-2 items-center">
              <div className="w-[20px] h-[20px] rounded-full bg-primary text-[10px] font-medium text-white flex justify-center items-center">
                CA
              </div>
              <div>
                <p className="text-[#344335] font-semibold text-sm">
                  Celina Avong
                </p>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-xs text-[#7C8D7E] font-semibold">
                Generated <span className="text-[#344335]">Account Number</span>
              </p>
            </div>
            <div className="mt-1">
              <p className="text-xs text-[#344335] font-medium">
                12:32 AM | 12 Aug 22
              </p>
            </div>
          </div>

          <div className="mt-5">
            <div className="flex gap-2 items-center">
              <div className="w-[20px] h-[20px] rounded-full bg-primary text-[10px] font-medium text-white flex justify-center items-center">
                CA
              </div>
              <div>
                <p className="text-[#344335] font-semibold text-sm">
                  Godswill Okon
                </p>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-xs text-[#7C8D7E] font-semibold">
                Resolved <span className="text-[#344335]">Complaints</span>
              </p>
            </div>
            <div className="mt-1">
              <p className="text-xs text-[#344335] font-medium">
                12:32 AM | 12 Aug 22
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="bg-white rounded-[8px] py-4 px-4 border border-[#ECEFEC]">
          <p className="text-[#344335] text-sm font-semibold">Documents</p>

          <div className="flex justify-between items-center mt-5">
            <div className="flex gap-3 items-center">
              {farmer?.image ? (
                <div className="w-[48px] h-[48px] rounded-full">
                  <ImageZoom>
                    <img
                      src={farmer?.image}
                      alt="passport icon"
                      className="w-full h-[48px] rounded-full"
                    />
                  </ImageZoom>
                </div>
              ) : (
                <div className="w-[48px] h-[48px] rounded-full">
                  <img src={passport_icon} alt="passport icon" />
                </div>
              )}

              <div className="flex gap-4">
                <div>
                  <p className="text-[#344335] text-sm font-semibold">
                    Passport Paragraph
                  </p>
                  <p className="text-[#5C715E] text-xs font-medium mt-1">
                    20 KB
                  </p>
                </div>
                <div>
                  {farmer?.image ? (
                    <div className="flex border border-primary px-1 py-1 text-primary rounded-[8px] font-medium text-xs w-fit">
                      Matched
                    </div>
                  ) : (
                    <div className="flex border border-[#B92020] px-1 py-1 text-[#B92020] rounded-[8px] font-medium text-xs w-fit">
                      Unmatched
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <ChevronRight className="text-[#5C715E] cursor-pointer" />
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <div className="flex gap-3 items-center">
              {farmer?.kyc.some((kyc: any) => kyc.kyc_type === "nin") ? (
                <div className="w-[48px] h-[48px] rounded-full">
                  <ImageZoom>
                    <img
                      src={
                        farmer.kyc.find((kyc: any) => kyc.kyc_type === "nin")
                          ?.image
                      }
                      alt="government icon"
                      className="w-[48px] h-[48px] rounded-full"
                    />
                  </ImageZoom>
                </div>
              ) : (
                <div className="w-[48px] h-[48px] rounded-full opacity-[50%]">
                  <img src={government_icon} alt="government icon" />
                </div>
              )}

              <div className="flex gap-4">
                {farmer?.kyc.some((kyc: any) => kyc.kyc_type === "nin") ? (
                  <div>
                    <p className="text-[#344335] text-sm font-semibold">
                      National Identity Slip/Card
                    </p>
                    <p className="text-[#5C715E] text-xs font-medium mt-1">
                      20 KB
                    </p>
                  </div>
                ) : (
                  <div className="opacity-[50%]">
                    <p className="text-[#344335] text-sm font-semibold">
                      National Identity Slip/Card
                    </p>
                    <p className="text-[#5C715E] text-xs font-medium mt-1">
                      20 KB
                    </p>
                  </div>
                )}
                <div>
                  {farmer?.kyc.some((kyc: any) => kyc.kyc_type === "nin") ? (
                    <div className="flex border border-primary px-1 py-1 text-primary rounded-[8px] font-medium text-xs w-fit">
                      Uploaded
                    </div>
                  ) : (
                    <div className="flex border border-[#96A397] px-1 py-1 text-[#96A397] rounded-[8px] font-medium text-xs w-fit">
                      Not Uploaded
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <ChevronRight className="text-[#5C715E] cursor-pointer" />
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <div className="flex gap-3 items-center">
              {farmer?.kyc.some(
                (kyc: any) => kyc.kyc_type === "voters_card"
              ) ? (
                <div className="w-[48px] h-[48px] rounded-full">
                  <ImageZoom>
                    <img
                      src={
                        farmer.kyc.find(
                          (kyc: any) => kyc.kyc_type === "voters_card"
                        )?.image
                      }
                      alt="voter icon"
                      className="w-[48px] h-[48px] rounded-full"
                    />
                  </ImageZoom>
                </div>
              ) : (
                <div className="w-[48px] h-[48px] rounded-full opacity-[50%]">
                  <img src={voters_icon} alt="voter icon" />
                </div>
              )}

              <div className="flex gap-4">
                {farmer?.kyc.some(
                  (kyc: any) => kyc.kyc_type === "voters_card"
                ) ? (
                  <div>
                    <p className="text-[#344335] text-sm font-semibold">
                      Voter's Card
                    </p>
                    <p className="text-[#5C715E] text-xs font-medium mt-1">
                      20 KB
                    </p>
                  </div>
                ) : (
                  <div className="opacity-[50%]">
                    <p className="text-[#344335] text-sm font-semibold">
                      Voter's Card
                    </p>
                    <p className="text-[#5C715E] text-xs font-medium mt-1">
                      20 KB
                    </p>
                  </div>
                )}
                <div>
                  {farmer?.kyc.some(
                    (kyc: any) => kyc.kyc_type === "voters_card"
                  ) ? (
                    <div className="flex border border-primary px-1 py-1 text-primary rounded-[8px] font-medium text-xs w-fit">
                      Uploaded
                    </div>
                  ) : (
                    <div className="flex border border-[#96A397] px-1 py-1 text-[#96A397] rounded-[8px] font-medium text-xs w-fit">
                      Not Uploaded
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <ChevronRight className="text-[#5C715E] cursor-pointer" />
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <div className="flex gap-3 items-center">
              {farmer?.kyc.some(
                (kyc: any) => kyc.kyc_type === "driver_license"
              ) ? (
                <div className="w-[48px] h-[48px] rounded-full">
                  <ImageZoom>
                    <img
                      src={
                        farmer.kyc.find(
                          (kyc: any) => kyc.kyc_type === "driver_license"
                        )?.image
                      }
                      alt="driverlicense icon"
                      className="w-[48px] h-[48px] rounded-full"
                    />
                  </ImageZoom>
                </div>
              ) : (
                <div className="w-[48px] h-[48px] rounded-full opacity-[50%]">
                  <img src={driverlicense} alt="driverlicense icon" />
                </div>
              )}
              <div className="flex gap-4">
                {farmer?.kyc.some(
                  (kyc: any) => kyc.kyc_type === "driver_license"
                ) ? (
                  <div>
                    <p className="text-[#344335] text-sm font-semibold">
                      Driver's License
                    </p>
                    <p className="text-[#5C715E] text-xs font-medium mt-1">
                      20 KB
                    </p>
                  </div>
                ) : (
                  <div className="opacity-[50%]">
                    <p className="text-[#344335] text-sm font-semibold">
                      Driver's License
                    </p>
                    <p className="text-[#5C715E] text-xs font-medium mt-1">
                      20 KB
                    </p>
                  </div>
                )}

                <div>
                  {farmer?.kyc.some(
                    (kyc: any) => kyc.kyc_type === "driver_license"
                  ) ? (
                    <div className="flex border border-primary px-1 py-1 text-primary rounded-[8px] font-medium text-xs w-fit">
                      Uploaded
                    </div>
                  ) : (
                    <div className="flex border border-[#96A397] px-1 py-1 text-[#96A397] rounded-[8px] font-medium text-xs w-fit">
                      Not Uploaded
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <ChevronRight className="text-[#5C715E] cursor-pointer" />
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <div className="flex gap-3 items-center">
              {farmer?.kyc.some(
                (kyc: any) => kyc.kyc_type === "international_passport"
              ) ? (
                <div className="w-[48px] h-[48px] rounded-full">
                  <ImageZoom>
                    <img
                      src={
                        farmer.kyc.find(
                          (kyc: any) =>
                            kyc.kyc_type === "international_passport"
                        )?.image
                      }
                      alt="international_passport icon"
                      className="w-[48px] h-[48px] rounded-full"
                    />
                  </ImageZoom>
                </div>
              ) : (
                <div className="w-[48px] h-[48px] rounded-full opacity-[50%]">
                  <img src={interpassport} alt="international_passport icon" />
                </div>
              )}

              <div className="flex gap-4">
                {farmer?.kyc.some(
                  (kyc: any) => kyc.kyc_type === "international_passport"
                ) ? (
                  <div>
                    <p className="text-[#344335] text-sm font-semibold">
                      International Passport
                    </p>
                    <p className="text-[#5C715E] text-xs font-medium mt-1">
                      20 KB
                    </p>
                  </div>
                ) : (
                  <div className="opacity-[50%]">
                    <p className="text-[#344335] text-sm font-semibold">
                      International Passport
                    </p>
                    <p className="text-[#5C715E] text-xs font-medium mt-1">
                      20 KB
                    </p>
                  </div>
                )}
                <div>
                  {farmer?.kyc.some(
                    (kyc: any) => kyc.kyc_type === "international_passport"
                  ) ? (
                    <div className="flex border border-primary px-1 py-1 text-primary rounded-[8px] font-medium text-xs w-fit">
                      Uploaded
                    </div>
                  ) : (
                    <div className="flex border border-[#96A397] px-1 py-1 text-[#96A397] rounded-[8px] font-medium text-xs w-fit">
                      Not Uploaded
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <ChevronRight className="text-[#5C715E] cursor-pointer" />
            </div>
          </div>
        </div>
        <div className="bg-white rounded-[8px] py-4 px-4 border border-[#ECEFEC] h-fit">
          <p className="text-[#344335] text-sm font-semibold">Next Of Kin</p>

          <div className="flex justify-between items-center mt-4">
            <div className="flex gap-3 items-center">
              <div className="w-[40px] h-[40px] rounded-full bg-[#2B8C34] text-white text-sm font-medium flex justify-center items-center">
                AJ
              </div>
              <div>
                <p className="text-[#344335] text-sm font-semibold">
                  Abubakar Jesam
                </p>
                <div className="flex gap-2 items-center cursor-pointer">
                  <p className="text-[#2B8C34] text-xs font-medium">
                    +2347060825698
                  </p>
                  <ContentCopy
                    className="text-primary"
                    style={{ fontSize: "13px" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <p className="text-[#7C8D7E] text-sm ">Address</p>
            <p className="text-[#344335] text-sm font-medium">
              6B Ibrahim Babangida Way, Apartment 3D, Maitama, Abuja Federal
              Capital Territory
            </p>
          </div>

          <div className="mt-4">
            <p className="text-[#7C8D7E] text-sm ">Relationship</p>
            <p className="text-[#344335] text-sm font-medium">Brother</p>
          </div>
        </div>
      </div>

      {/* RANK MODAL */}
      <ModalComponent
        isOpen={show}
        onClose={() => setShow(false)}
        title="Ranks"
        subtitle=""
      >
        <div className="my-[24px]">
          {/* Platinum */}
          <div className="mb-5">
            <div className="bg-[#fff] border-[1px] border-[#2B8C34] text-[#2B8C34]  w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className=" leading-5 text-sm font-medium">Verified</p>
            </div>
            <p className="text-xs font-medium leading-4 text-[#344335] pt-2">
              Verification of all PIDs shows this farmer is real. Farm activity
              not yet determined.
            </p>
          </div>

          {/* Unverified */}
          <div className="mb-5">
            <div className="bg-[#2B8C34] border-[1px] border-[#2B8C34] text-[#fff]  w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className=" leading-5 text-sm font-medium">Active</p>
            </div>
            <p className="text-xs font-medium leading-4 text-[#344335] pt-2">
              Verification of all PIDs shows this farmer is real. Farmer is
              currently cultivating.
            </p>
          </div>

          {/* Incomplete */}
          <div className="mb-5">
            <div className="bg-[#fff] border-[1px] border-[#B92020] text-[#B92020] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className=" leading-5 text-sm font-medium">Unverified</p>
            </div>
            <p className="text-xs font-medium leading-4 text-[#344335] pt-2">
              Verification of important PIDs has not been completed.
            </p>
          </div>

          {/* Bronxe */}
          <div className="mb-5">
            <div className="bg-[#B92020] border-[1px] border-[#B92020] text-[#fff] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className=" leading-5 text-sm font-medium">
                Likely Fraudulent
              </p>
            </div>
            <p className="text-xs font-medium leading-4 text-[#344335] pt-2">
              Verification of all PIDs shows this farmer is likely an impostor.
            </p>
          </div>

          <div className="mb-5">
            <div className="bg-[#fff] border-[1px] border-[#FFC508] text-[#FFC508] w-[fit-content] cursor-pointer flex h-6 gap-[8px] items-center rounded-[4px] px-2 py-1">
              <p className=" leading-5 text-sm font-medium">Incomplete</p>
            </div>
            <p className="text-xs font-medium leading-4 text-[#344335] pt-2">
              Farmer Data has not been completely captured.
            </p>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default AdminFarmerInformationPage;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const fetchAgents = createAsyncThunk(
  "fetchAgents",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}gather/retrieve-agents/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${getToken()}`,
        },
      });
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Cannot retreive agents at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Cannot retreive agents at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  retrieveLgas,
  retrieveStates,
  retrieveCrops,
  retrieveLivestock,
  userLogin,
} from "./authActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

type statesType = {
  display_name: string;
  id: number;
  name: string;
};

type cropsType = {
  name: string;
};

type AuthState = {
  isAuth: boolean;
  last_name: string;
  first_name: string;
  loading: boolean;
  token: string;
  states: statesType[];
  lgas: statesType[];
  crops: cropsType[];
  livestock: cropsType[];
};

const initialState: AuthState = {
  isAuth: false,
  first_name: "",
  last_name: "",
  loading: false,
  token: "",
  states: [],
  lgas: [],
  crops: [],
  livestock: [],
};

// Configure Redux Persist
const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["loading"],
  // Add any additional configuration options as needed
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },
    logIn: (state, action: PayloadAction<string>) => {
      state.isAuth = true;
    },
  },
  extraReducers: (builder) => {
    // login
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.token = action.payload?.token;
        state.first_name = action.payload?.first_name;
        state.last_name = action.payload?.last_name;
        localStorage.setItem("token", action.payload.token);
      })
      .addCase(userLogin.rejected, (state) => {
        state.loading = false;
        state.isAuth = false;
      });
    //  retrieve states
    builder.addCase(retrieveStates.pending, (state, action) => {});
    builder.addCase(
      retrieveStates.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.states = action.payload.sort((a: any, b: any) =>
          a?.name > b?.name ? 1 : -1
        );
      }
    );
    builder.addCase(retrieveStates.rejected, (state, action) => {});
    // retrieve lgas
    builder.addCase(retrieveLgas.pending, (state, action) => {});
    builder.addCase(
      retrieveLgas.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.lgas = action.payload;
      }
    );
    builder.addCase(retrieveLgas.rejected, (state, action) => {});
    // retrieve crops
    builder.addCase(retrieveCrops.pending, (state, action) => {});
    builder.addCase(
      retrieveCrops.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.crops = action.payload;
      }
    );
    builder.addCase(retrieveCrops.rejected, (state, action) => {});
    // retrieve livestock
    builder.addCase(retrieveLivestock.pending, (state, action) => {});
    builder.addCase(
      retrieveLivestock.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.livestock = action.payload;
      }
    );
    builder.addCase(retrieveLivestock.rejected, (state, action) => {});
  },
});

const authReducer = persistReducer(authPersistConfig, authSlice.reducer);
export const { logOut, logIn } = authSlice.actions;

export default authReducer;

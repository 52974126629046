import React,{useState, useCallback, useMemo, useEffect} from 'react'
import { Add } from "@mui/icons-material";
import note_icon from '../../../assets/icons/note.svg'
import SearchComponent from '../../../components/Search';
import { debounce } from "lodash";
import { useNavigate } from 'react-router-dom';
import DataCapturePage from '../../../pages/Agent/Data/DataCapture';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { fetchAgentFarmers } from '../../../redux/Farmers/farmerActions';
import PaginationComponent from "../../../components/Pagination/pagination";


const AgentDataTable = () => {

    const [searchValue, setSearchValue] = useState("");
    const [typeID, setTypeID] = useState(1);
    const dispatch = useAppDispatch()
    const { farmers, loading, total } = useAppSelector((state) => state.farmer);
    const [currentPage, setCurrentPage] = useState(1);


    const [isShow, setShow] = React.useState(false);
    const handleFarmerData = () =>{
       setShow(!isShow);
    }

    const callTypes = [
        {
          id: 1,
          title: "All",
        },
        {
          id: 2,
          title: "Incomplete",
        },
        {
          id: 3,
          title: "Complete",
        },
      ];
    
      const toggleType = (id: number) => {
        setTypeID(id);
      }

      const navigate = useNavigate()

     
      const handleProfile = (id: string, phone_number: string) => {
        navigate(`/data/${id}/${phone_number}`);
      };

    const handleSearch = useMemo(
        () =>
          debounce((val: string) => {
            console.log(val);
          }, 750),
        []
      );

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
          let val = e.target.value;
          setSearchValue(val);
          handleSearch(val);
        },
        [handleSearch]
    );

    const onPageChange = (page: number) => {
      setCurrentPage(page);
      dispatch(fetchAgentFarmers({
        page: page
      }))
    }

    useEffect(()=>{
      dispatch(fetchAgentFarmers({
        page: 1
      }))
    },[dispatch])

    return (
        <>
         {/*  */}
         <DataCapturePage isCreateCat={isShow} onClose={handleFarmerData} /> 

      
         <div className="mt-6 bg-white rounded-[8px]">
            <div className="p-4">
                <p className="text-primary font-semibold text-base leading-5">
                Farmer Data Details
                    </p>
                <p className="text-[#7C8D7E] text-sm font-medium mt-1">
                This is a summary of farmers data gathered
                </p>

                   {/* search */}
                <div className="flex gap-4 mt-3">
                    <div className="w-full">
                    <SearchComponent
                        placeholder="Search"
                        searchValue={searchValue}
                        handleChange={handleChange}
                    />
                    </div> 
                </div>


          <ul className="flex mt-5 gap-5 items-center">
            {callTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer relative"
                    : "flex gap-1 items-center pb-1 cursor-pointer relative"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
              </li>
            ))}
          </ul>

            </div>

          <div className="relative">
            {loading ? (
              <div>
                <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                  <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                </div>
              </div>
            ) : (
              <>
                {farmers?.length === 0 ? (
                <div className='flex flex-col justify-center items-center min-h-[500px]'>
                    <div className="w-[64px] h-[64px] rounded-full flex justify-center items-center bg-[#EDF7EE]">
                      <img src={note_icon} alt="note" />
                    </div>
                    <p className="text-[#344335] text-sm font-semibold mt-2">No data gathered yet</p>
                    <div className="mt-2 max-w-xs text-center">
                        <p className="text-[#96A397] text-sm font-semibold">There is currently no data gathered on this farmer. Would you like to create one?</p>
                    </div>
                    <button
                            type="submit"
                            onClick={handleFarmerData}
                            className="bg-primary mt-5 
                                        disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                        rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                            >
                                Add Farmer Data
                                <Add style={{ fontSize: "18px" }} />
                          </button>
                  </div>
                ) : (
                  <section className="overflow-x-auto relative">
                    <table className="w-full text-left">
                      <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                      <tr>
                        <th scope="col" className="px-4 py-4 font-semibold ">
                        First Name
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                        Last Name 
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                        Phone Number
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                        Crops
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                        State
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                            Local Government
                        </th>
                        <th scope="col" className="px-4 py-4 font-semibold">
                          Registration Status
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                        {farmers?.map((val, index) => {
                          return (
                            <tr
                            key={val.id}
                            onClick={() =>
                              handleProfile(val?.id, val?.phone_number)
                            }
                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                        >
                            <th
                            scope="row"
                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                            >
                            {val.first_name}
                            </th>
                            <td className="py-4 px-4 text-[#5C715E]">
                            {val.last_name}
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                            {val.phone_number} 
                            </td>
                      <td className="py-4 capitalize px-4 text-[#5C715E]">
                            {
                              val?.crops.map((item, index) => (
                                index === val.crops.length - 1 ? item : `${item}, `
                              )).join('')
                            }
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                     {val?.state || "N/A"}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                      {val?.lga || "N/A"}
                      </td>
                    
                      <td className="py-4 px-4 text-[#5C715E]">
                          {val.is_complete ?
                          <div className="flex">
                              <div className="border border-[#2B8C34] rounded-[16px] px-2 py-1 text-sm text-[#2B8C34]">
                                Complete
                              </div>
                          </div>
                          :
                          <div className="flex">
                          <div className="border border-[#D4A407] rounded-[16px] px-2 py-1 text-sm text-[#D4A407]">
                            Incomplete
                          </div>
                      </div>
                        }
                      </td>
                        </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </section>
                )}
              </>
            )}
           </div>
           <div>
              <PaginationComponent
                page={currentPage}
                onPageChange={(page) => onPageChange(page)}
                totalItems={total}
                pageSize={20}
              />
            </div>

        </div>
        </>
      );
}
 
export default AgentDataTable;
import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { retrieveFarms } from "../../../../redux/Farmers/farmerActions";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { Polygon } from "../../../../components/Polygon/polygon";

type InfoType = {
  id?: string | undefined;
};

const AdminFarmDetailsPage = ({ id }: InfoType) => {
  const dispatch = useAppDispatch();
  const API_KEY = "AIzaSyCAyoLnnbfQjG7R1caK4_FrGsVFIdbRObY";
  // const [infowindowOpen, setInfowindowOpen] = useState(true);

  const { farmDetailsLoading, farmDetails, coordinates } = useAppSelector(
    (state) => state.farmer
  );

  useEffect(() => {
    dispatch(retrieveFarms({ farmerId: id }));
  }, [dispatch, id]);

  const center = useMemo(() => {
    if (!coordinates || !coordinates.length) return { lat: 0, lng: 0 };

    const bounds = coordinates.reduce(
      (acc, coord) => {
        acc.minLat = Math.min(acc.minLat, coord.lat);
        acc.maxLat = Math.max(acc.maxLat, coord.lat);
        acc.minLng = Math.min(acc.minLng, coord.lng);
        acc.maxLng = Math.max(acc.maxLng, coord.lng);
        return acc;
      },
      {
        minLat: Infinity,
        maxLat: -Infinity,
        minLng: Infinity,
        maxLng: -Infinity,
      }
    );

    return {
      lat: (bounds.minLat + bounds.maxLat) / 2,
      lng: (bounds.minLng + bounds.maxLng) / 2,
    };
  }, [coordinates]);

  return (
    <>
      {Array.isArray(farmDetails) ? (
        <div> </div>
      ) : (
        <div className="bg-white rounded-[8px] px-4 py-4 border border-[#ECEFEC] mb-[90px]">
          <p className="text-sm text-[#344335] font-semibold">Farm Details </p>
          {farmDetailsLoading ? (
            <div className="my-[20vh] flex justify-center items-center">
              <p className="text-base">Loading....</p>
            </div>
          ) : (
            <>
              <div className="mt-5 flex gap-4">
                <div className="border border-[#DDE2DE] rounded-[8px] py-4 px-4 flex gap-4 max-w-sm min-h-[120px]">
                  <div className="flex flex-col justify-between flex-1">
                    <h6 className="text-[#5C715E] text-sm font-semibold">
                      Cultivation Efficiency
                    </h6>
                    <h4 className="text-[#344335] text-2xl font-semibold">
                      {farmDetails?.cultivation_percentage || 0}%
                    </h4>
                  </div>
                  <div className="bg-[#ECEFEC] w-[1px]"></div>
                  <div className="flex flex-col justify-between items-start flex-1">
                    <h6 className="text-[#5C715E] text-xs font-medium">
                      This is the performance of the farmer In relation to land
                      size.
                    </h6>
                    <button className="text-primary text-xs font-semibold">
                      View Recommendation
                    </button>
                  </div>
                </div>
                <div className="border border-[#DDE2DE] rounded-[8px] py-4 px-4 flex gap-4 max-w-sm min-h-[120px]">
                  <div className="flex flex-col justify-between flex-1">
                    <h6 className="text-[#5C715E] text-sm font-semibold">
                      Average Historical Yield(tonne(s))
                    </h6>
                    <h4 className="text-[#344335] text-2xl font-semibold">4</h4>
                  </div>
                  <div className="bg-[#ECEFEC] w-[1px]"></div>
                  <div className="flex flex-col justify-between items-start flex-1">
                    <h6 className="text-[#5C715E] text-xs font-medium">
                      The is the average tonne produced on this farm
                    </h6>
                  </div>
                </div>
              </div>

              <div className="flex mt-6">
                {/* FARM STATUS */}
                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Farm Status</p>
                  <div
                    className={`capitalize ${
                      farmDetails?.farm_tag === "complete" ||
                      farmDetails?.farm_tag === "verified" ||
                      farmDetails?.farm_tag === "active"
                        ? "success"
                        : farmDetails?.farm_tag === "inactive"
                        ? "failure"
                        : farmDetails?.farm_tag === "incomplete" ||
                          farmDetails?.farm_tag === "incomplete farm capture"
                        ? "pending"
                        : farmDetails?.farm_tag === "season_inactive"
                        ? "error"
                        : farmDetails?.farm_tag === "low_cultivation"
                        ? "info"
                        : "na-light"
                    } rounded-[16px] flex items-center justify-center px-2 py-1 w-fit `}
                  >
                    <p className="text-sm font-medium leading-[18px]">
                      {farmDetails?.farm_tag || "Nill"}
                    </p>
                  </div>
                </div>
                {/* CROPS */}
                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Crop</p>
                  <div className="flex flex-wrap text-sm capitalize">
                    {farmDetails?.crops?.length === 0
                      ? "Nill"
                      : farmDetails?.crops
                          ?.map((item, index) =>
                            index === farmDetails.crops.length - 1
                              ? item
                              : `${item}, `
                          )
                          .join("")}
                  </div>
                </div>
                {/* LIVESTOCK */}
                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Livestock</p>
                  <div className="flex flex-wrap text-sm capitalize">
                    {farmDetails?.livestock?.length === 0
                      ? "Nill"
                      : farmDetails?.livestock
                          ?.map((item, index) =>
                            index === farmDetails.livestock.length - 1
                              ? item
                              : `${item}, `
                          )
                          .join("")}
                  </div>
                </div>
                {/* SIZE */}
                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Size</p>
                  <p className="mt-1 font-medium text-sm text-[#344335]">
                    {parseFloat(farmDetails?.size)?.toFixed(2) || 0} Ha
                  </p>
                </div>

                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Perimeter</p>
                  <p className="mt-1 font-medium text-sm text-[#344335]">
                    {parseFloat(farmDetails?.perimeter)?.toFixed(2) || 0} Meters
                  </p>
                </div>
                {/* STATE */}
                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">State</p>
                  <p className="mt-1 font-medium text-sm text-[#344335]">
                    {" "}
                    {farmDetails?.state || "Nill"}
                  </p>
                </div>
              </div>

              <div className="flex mt-6">
                {/* LGA */}
                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Local Government</p>
                  <p className="mt-1 font-medium text-sm text-[#344335]">
                    {" "}
                    {farmDetails?.lga || "Nill"}
                  </p>
                </div>

                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Farming Season</p>
                  <p className="mt-1 font-medium text-sm text-[#344335] capitalize">
                    {" "}
                    {farmDetails?.current_farming_season || "Nill"}
                  </p>
                </div>

                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Farm Boundaries</p>
                  {farmDetails?.boundaries_captured ? (
                    <div className="success">Captured</div>
                  ) : (
                    <div className="failure">Not Captured</div>
                  )}
                </div>

                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Satellite Validation</p>
                  {farmDetails?.satellite_validated ? (
                    <div className="success">Verified Farm</div>
                  ) : (
                    <div className="failure">Not Verified Farm</div>
                  )}
                </div>

                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Current Season</p>
                  <div className="">Oct., 2023 - Mar., 2024</div>
                </div>
                <div className="flex-1">
                  <p className="text-sm text-[#7C8D7E]">Location Coordinate</p>
                  <div
                    className={`${
                      coordinates?.length !== 0 ? "success-bold" : "na"
                    }`}
                  >
                    {coordinates?.length !== 0
                      ? `${coordinates[0]?.lat}, ${coordinates[0]?.lng}`
                      : "Nill"}
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <div className="w-full h-[500px]">
                  <APIProvider apiKey={API_KEY}>
                    <div className="h-[500px] rounded-[4px] w-full">
                      <Map
                        mapId="d10801052e62b784"
                        disableDefaultUI={true}
                        mapTypeId="satellite"
                        gestureHandling={"greedy"}
                        zoom={17}
                        center={center}
                      >
                        <Polygon
                          strokeWeight={3}
                          paths={coordinates}
                          strokeColor={"#FFC508"}
                          strokeOpacity={1}
                          fillColor={"#FFFFFF66"}
                          fillOpacity={0.7}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            fontWeight: 500,
                            color: "white",
                            transform: "translate(-50%, -50%)",
                            zIndex: 999,
                          }}
                        >
                          {parseFloat(farmDetails?.size)?.toFixed(2) || 0} Ha
                        </div>
                      </Map>
                    </div>
                  </APIProvider>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AdminFarmDetailsPage;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";

// const getToken = () => {
//   const token = localStorage.getItem("token");
//   return token;
// };

interface LoginCredentials {
  token: string;
}

export interface LoginApiResponse {
  // Define the properties you expect in the API response
  token: string;
  // Add other properties as needed
}

type stateIDType = {
  id: number;
};

export const userLogin = createAsyncThunk(
  "login",
  async (credentials: LoginCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/login/`, {
        token: credentials.token,
      });
      if (res.status === 200) {
        let data = res.data.data;
        toast.success("Login successful!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "bottom-right",
        });
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Invalid Credentials!");
        return rejectWithValue(error);
      } else {
        toast.error("Invalid Credentials!");
        return rejectWithValue(error);
      }
    }
  }
);

export const retrieveStates = createAsyncThunk(
  "retrieveStates",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}gather/state-list/`, {
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `token ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        let data = res.data.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const retrieveLgas = createAsyncThunk(
  "retrieveLgas",
  async ({ id }: stateIDType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}gather/lga-list/?state_id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `token ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        let data = res.data.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const retrieveCrops = createAsyncThunk(
  "retrieveCrops",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}gather/crop-list/`, {
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `token ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        let data = res.data.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const retrieveLivestock = createAsyncThunk(
  "retrieveLivestock",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}gather/livestock-list/`, {
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `token ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        let data = res.data.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  fetchAgentFarmer,
  fetchAgentFarmers,
  fetchReports,
  fetchReportsOverview,
  fetchReportsTypes,
  retrieveFarms,
} from "./farmerActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

type FarmersType = {
  first_name: string;
  gender: string;
  last_name: string;
  phone_number: string;
  state: string;
  lga: string;
  id: string;
  is_complete: boolean;
  crops: [];
  farmer_tag: string;
  farm_tag: string;
};

type KYCType = {
  kyc_type: string;
  is_verified: boolean;
  kyc_number: string;
  image: string;
};

export type coordinateType = {
  lat: number | null;
  lng: number | null;
};

export type coordinateTypeTwo = {
  lat: number;
  lng: number;
};

// type reportsType = {
//   avg_crop_yield: any;
//   avg_expenditure: any;
//   avg_income: any;
//   crop_yield_reports: reportType[];
//   expenditure_reports: reportType[];
//   income_reports: reportType[];
//   other_reports: reportType[];
// };

export type reportType = {
  created: string;
  created_by: string | null;
  description: string | null;
  document: string | null;
  farmer: string;
  id: number;
  report_for_date: string;
  report_type: string;
  reported_by: string;
  value: number;
};

function isArrayofObjects(variable: any) {
  if (!Array.isArray(variable)) {
    return false;
  }

  for (let element of variable) {
    if (typeof element !== "object" || Array.isArray(element)) {
      return false;
    }
  }

  return true;
}

type FarmersState = {
  loading: boolean;
  farmers: FarmersType[];
  total: number;
  loadingfarmer: boolean;
  farmer: {
    created: string;
    date_of_birth: string;
    age: string;
    crops: string[];
    first_name: string;
    gender: string;
    id: string;
    is_complete: boolean | null;
    kyc: KYCType[];
    last_name: string;
    lga: string;
    livestock: string[];
    percentage_completion: number | null;
    phone_number: string;
    state: string;
    image: string;
    bvn_verified: boolean;
    farmer_tag: null | string;
    nin_verified: boolean;
    nin: number | null;
  };
  coordinates: coordinateTypeTwo[];
  farmDetails: {
    address: string;
    coordinates: coordinateType[];
    crops: string[];
    farmer: string;
    id: string;
    livestock: string[];
    measurement: string;
    perimeter: string;
    size: string;
    state: number | string | null;
    lga: number | string | null;
    boundaries_captured: boolean;
    cultivation_percentage: number;
    satellite_validated: boolean;
    farm_tag: string;
    current_farming_season: string;
  };
  farmDetailsLoading: boolean;
  reports: reportType[];
  reportsLoading: boolean;
  reportsType: any;
  reportsOverview: any;
};

const initialState: FarmersState = {
  loading: false,
  farmers: [],
  total: 0,
  loadingfarmer: false,
  coordinates: [
    {
      lat: 0,
      lng: 0,
    },
  ],
  farmDetails: {
    address: "",
    coordinates: [
      {
        lat: null,
        lng: null,
      },
    ],
    crops: [],
    farmer: "",
    id: "",
    livestock: [],
    measurement: "",
    perimeter: "",
    size: "",
    state: null,
    lga: null,
    farm_tag: "",
    current_farming_season: "",
    boundaries_captured: false,
    cultivation_percentage: 0,
    satellite_validated: false,
  },
  farmDetailsLoading: false,
  farmer: {
    date_of_birth: "",
    created: "",
    age: "",
    crops: [],
    first_name: "",
    gender: "",
    id: "",
    is_complete: null,
    kyc: [],
    last_name: "",
    lga: "",
    livestock: [],
    percentage_completion: null,
    phone_number: "",
    state: "",
    image: "",
    bvn_verified: false,
    nin_verified: false,
    farmer_tag: null,
    nin: null,
  },
  reports: [],
  reportsType: {},
  reportsLoading: false,
  reportsOverview: {},
};

// Configure Redux Persist
const farmerPersistConfig = {
  key: "farmer",
  storage,
  blacklist: ["loading"],
  // Add any additional configuration options as needed
};

export const farmerSlice = createSlice({
  name: "farmer",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentFarmers.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchAgentFarmers.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.farmers = action.payload.data;
          state.total = action.payload.count;
        }
      )
      .addCase(fetchAgentFarmers.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(fetchAgentFarmer.pending, (state) => {
        state.loadingfarmer = true;
      })
      .addCase(
        fetchAgentFarmer.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loadingfarmer = false;
          state.farmer = action.payload.data;
        }
      )
      .addCase(fetchAgentFarmer.rejected, (state) => {
        state.loadingfarmer = false;
      });

    builder
      .addCase(retrieveFarms.pending, (state) => {
        state.farmDetailsLoading = true;
      })
      .addCase(retrieveFarms.fulfilled, (state, action: PayloadAction<any>) => {
        state.farmDetailsLoading = false;
        state.farmDetails = action.payload.data;

        if (isArrayofObjects(action?.payload?.data?.coordinates)) {
          state.coordinates = action?.payload?.data?.coordinates;
        } else {
          const arrayOfObjects = action?.payload?.data?.coordinates?.flatMap(
            (arr: any) => arr?.concat()
          );
          state.coordinates = arrayOfObjects;
        }
      })
      .addCase(retrieveFarms.rejected, (state) => {
        state.farmDetailsLoading = false;
      });

    // REPORTS

    builder
      .addCase(fetchReports.pending, (state) => {
        state.reportsLoading = true;
      })
      .addCase(fetchReports.fulfilled, (state, action: PayloadAction<any>) => {
        state.reportsLoading = false;
        state.reports = action.payload.data;
      })
      .addCase(fetchReports.rejected, (state) => {
        state.reportsLoading = false;
      });

    // REPORTS TYPES

    builder
      .addCase(fetchReportsTypes.pending, (state) => {
        state.reportsLoading = true;
      })
      .addCase(
        fetchReportsTypes.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.reportsLoading = false;
          state.reportsType = action.payload.data;
        }
      )
      .addCase(fetchReportsTypes.rejected, (state) => {
        state.reportsLoading = false;
      });

    builder
      .addCase(fetchReportsOverview.pending, (state) => {
        state.reportsLoading = true;
      })
      .addCase(
        fetchReportsOverview.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.reportsLoading = false;
          state.reportsOverview = action.payload.data;
        }
      )
      .addCase(fetchReportsOverview.rejected, (state) => {
        state.reportsLoading = false;
      });
  },
});

const farmerReducer = persistReducer(farmerPersistConfig, farmerSlice.reducer);
export const { logOut } = farmerSlice.actions;

export default farmerReducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

type fetchAgentFarmersType = {
  page: number;
};

export const fetchAgentFarmers = createAsyncThunk(
  "fetchAgentFarmers",
  async ({ page }: fetchAgentFarmersType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}gather/retrieve-farmers/?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);
type fetchAgentFarmerType = {
  phone_number: string | undefined;
};
export const fetchAgentFarmer = createAsyncThunk(
  "fetchAgentFarmer",
  async (
    { phone_number }: fetchAgentFarmerType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.get(
        `${apiUrl}gather/retrieve-farmers/?phone_number=${phone_number}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

interface RegisterFarmersCredentials {
  first_name: string;
  last_name: string;
  state_id: number;
  lga_id: number;
  bvn: string;
  phone_number: string;
  date_of_birth: string;
  gender: string;
  farming_season: string;
  crops: string[];
  livestock: string[];
  kyc_image: string | Blob;
  kyc_type: string;
  kyc_number: string;
  image: string | Blob;
}

// export const registerFarmers = createAsyncThunk(
//   "registerFarmers",
//   async (
//     credentials: RegisterFarmersCredentials,
//     { rejectWithValue, dispatch }
//   ) => {
//     try {
//       const res = await axios.post(
//         `${apiUrl}gather/register-farmer/`,
//         {
//           first_name: credentials.first_name,
//           last_name: credentials.last_name,
//           state_id: credentials.state_id,
//           lga_id: credentials.lga_id,
//           bvn: credentials.bvn,

//           phone_number: credentials.phone_number,
//           date_of_birth: credentials.date_of_birth,
//           gender: credentials.gender,
//           farming_season: credentials.farming_season,
//           crops: credentials.crops,
//           livestock: credentials.livestock,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `token ${getToken()}`,
//           },
//         }
//       );
//       if (res.status === 200) {
//         toast.success(
//           "Farmer details added sucessfully, Please proceed to capture your farm",
//           {
//             style: {
//               border: "1px solid #2B8C34",
//               backgroundColor: "#2B8C34",
//               color: "#FFFFFF",
//               fontSize: 14,
//             },
//             position: "top-right",
//           }
//         );

//         return res.data;
//       }
//     } catch (error: any) {
//       if (error.response && error.response.status === 400) {
//         toast.error("Oops an error occured adding these details");
//         return rejectWithValue(error);
//       } else if (error.response.status === 401) {
//         dispatch({ type: "auth/logOut" });
//         return rejectWithValue(error);
//       } else {
//         toast.error("Oops an error occured adding these details");
//         return rejectWithValue(error);
//       }
//     }
//   }
// );

export const registerSelfFarmers = createAsyncThunk(
  "registerSelfFarmers",
  async (
    credentials: RegisterFarmersCredentials,
    { rejectWithValue, dispatch }
  ) => {
    const formDataToSend = new FormData();
    formDataToSend.append("first_name", credentials.first_name);
    formDataToSend.append("last_name", credentials.last_name);
    formDataToSend.append("state_id", credentials.state_id.toString());
    formDataToSend.append("date_of_birth", credentials.date_of_birth);
    formDataToSend.append("lga_id", credentials.lga_id.toString());
    formDataToSend.append("bvn", credentials.bvn);
    formDataToSend.append("phone_number", credentials.phone_number);
    formDataToSend.append("gender", credentials.gender);
    formDataToSend.append("farming_season", credentials.farming_season);
    formDataToSend.append("image", credentials.image);
    formDataToSend.append("kyc_type", credentials.kyc_type);
    formDataToSend.append("kyc_image", credentials.kyc_image);
    formDataToSend.append("kyc_number", credentials.kyc_number);

    credentials.crops.forEach((name: any, index: any) => {
      formDataToSend.append(`crops[${index}]`, name);
    });

    credentials.livestock.forEach((name: any, index: any) => {
      formDataToSend.append(`livestock[${index}]`, name);
    });

    try {
      const res = await axios.post(
        `${apiUrl}gather/register-farmer/?self_registration=${1}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.errors);
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("An error occured while registering farmer. Try again");
        return rejectWithValue(error);
      }
    }
  }
);

type cordinateType = {
  lng: number;
  lat: number;
};
interface CaptureFarmCredentials {
  size: string;
  id: string | null;
  perimeter: string;
  measurement?: string;
  state_id: number;
  lga_id: number;
  address?: string;
  coordinates: cordinateType[];
  crops: string[];
  livestock: string[];
}

export const captureFarm = createAsyncThunk(
  "captureFarm",
  async (
    credentials: CaptureFarmCredentials,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await axios.post(
        `${apiUrl}gather/${credentials.id}/create-farm/`,
        {
          size: credentials.size,
          perimeter: credentials.perimeter,
          measurement: "Acres",
          state_id: credentials.state_id,
          lga_id: credentials.lga_id,
          address: "",
          coordinates: credentials.coordinates,
          crops: credentials.crops,
          livestock: credentials.livestock,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("Farmer captured successfully", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });

        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops an error occured capturing this farm");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops an error occured capturing this farm");
        return rejectWithValue(error);
      }
    }
  }
);

type retrieveFarmsType = {
  farmerId: string | undefined;
};

export const retrieveFarms = createAsyncThunk(
  "retrieveFarms",
  async ({ farmerId }: retrieveFarmsType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}gather/retrieve-farms?farmer_id=${farmerId}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Oops an error occured capturing this farm");
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Oops an error occured capturing this farm");
        return rejectWithValue(error);
      }
    }
  }
);

type fetchReportsType = {
  id: string | undefined;
  type: string;
};
export const fetchReports = createAsyncThunk(
  "fetchReports",
  async ({ id, type }: fetchReportsType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}gather/${id}/retrieve-reports/?report_type=${type}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchReportsTypes = createAsyncThunk(
  "fetchReportsTypes",
  async ({ id, type }: fetchReportsType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}gather/${id}/retrieve-reports?report_type=${type}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const fetchReportsOverview = createAsyncThunk(
  "fetchReportsOverview",
  async ({ id, type }: fetchReportsType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}gather/${id}/retrieve-reports?graph_format=${1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        let data = res.data;
        return data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
        return rejectWithValue(error);
      } else {
        toast.error("Cannot retreive farmers at the moment.Kindly try again", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

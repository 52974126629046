import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchAgents } from "./agentsActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

type FarmersType = {
  first_name: string;
  gender: string;
  last_name: string;
  phone_number: string;
  state: string;
  lga: string;
  id: string;
  is_complete: boolean;
  crops: [];
};

type FarmersState = {
  loading: boolean;
  agents: FarmersType[];
};

const initialState: FarmersState = {
  loading: false,
  agents: [],
};

// Configure Redux Persist
const agentsPersistConfig = {
  key: "agents",
  storage,
  blacklist: ["loading"],
  // Add any additional configuration options as needed
};

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAgents.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.agents = action.payload.data;
      })
      .addCase(fetchAgents.rejected, (state) => {
        state.loading = false;
      });
  },
});

const agentReducer = persistReducer(agentsPersistConfig, agentsSlice.reducer);
export const { logOut } = agentsSlice.actions;

export default agentReducer;

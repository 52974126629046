import React from 'react'
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/icons/agrilens_white.svg";
import logout_icon from "../../assets/icons/logout.svg";
import chevron_down from "../../assets/icons/chevron_down.svg";
import chevron_up from "../../assets/icons/chevron_up.svg";
import notification_icon from "../../assets/icons/notifications.svg";
import { FolderShared, Notifications, Person } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { logOut } from '../../redux/Auth/auth';

type sidebarType = {
    children: React.ReactNode;
    title: string;
  };

const AgentSidebar = ({ children, title }: sidebarType) => {

    let location = useLocation();
    const [isNavMenu, setNavMenu] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { first_name, last_name } = useAppSelector((state) => state.auth);



    const toggleNavMenu = () => {
        setNavMenu(!isNavMenu);
    };

    const handleLogout = () => {
      dispatch(logOut());
      navigate("/");
    };
    

    return ( 
        <>
          <div className="h-screen flex bg-[#f5f5f5]">

            <div className="md:w-64 bg-primary lg:block hidden flex-shrink-0 overflow-y-scroll pt-[30px] sidebar_scroll">
                <img src={logo} alt="logo" className="pl-6  overflow-y-scroll" />

                <div className=" cursor-pointer mt-[40px]">
                {/* Data AND NOTIFICATIONS SECTION */}
                <section>
                  <NavLink
                    to="/data"
                    className={
                      location.pathname.includes("/data")
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                    }
                  >
                    <FolderShared style={{ fontSize: "18px" }} />
                    <p className="font-semibold text-sm capitalize leading-[18px]">
                      Data
                    </p>
                  </NavLink>

                  <NavLink
                    to="/notifications"
                    className={
                      location.pathname === "/notifications"
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                    }
                  >
                    <Notifications style={{ fontSize: "18px" }} />
                    <p className="font-semibold text-sm capitalize leading-[18px]">
                      Notifications
                    </p>
                  </NavLink>


                  <NavLink
                    to="/account"
                    className={
                      location.pathname === "/account"
                        ? "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-primary bg-white w-[98%] rounded-r-[8px]"
                        : "pl-[26.6px] flex gap-[18.5px] items-center  mb-[13px] py-[13px]  text-white hover:text-primary hover:bg-white w-[98%] rounded-r-[8px]"
                    }
                  >
                    <Person style={{ fontSize: "18px" }} />
                    <p className="font-semibold text-sm capitalize leading-[18px]">
                      Account
                    </p>
                  </NavLink>

                


                </section>
              </div>
            </div>

             <div className="py-4 flex-grow flex-auto flex-shrink overflow-y-scroll">
             {/* NAV BAR */}
              <div className="flex px-4 md:px-6 justify-between items-center">
                <div>
                  <p className="text-sm font-semibold leading-4 text-[#344335]">
                    {title}
                  </p>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full relative">
                    <img src={notification_icon} alt="notification" />
                    <div className="bg-[#B92043] w-[10px] h-[10px] rounded-full absolute -right-1 top-1 border-white border-2"></div>
                  </div>
                  <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full">
                    <p className="text-white text-xs font-medium">
                      {first_name ? first_name.charAt(0).toUpperCase() : "T"}
                      {last_name ? last_name.charAt(0).toUpperCase() : "U"}
                    </p>
                  </div>
                  <div
                    className="flex items-center gap-2 cursor-pointer relative"
                    onClick={toggleNavMenu}
                  >
                    <p className="text-[#5C715E] text-sm font-medium">
                      {first_name ? first_name : "Test"}{" "}
                      {last_name ? last_name : "User"}
                    </p>
                    <img
                      src={isNavMenu ? chevron_up : chevron_down}
                      alt="chevron arrow"
                    />

                    <ul
                      className={
                        isNavMenu
                          ? "absolute top-10 transition-all right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop z-10"
                          : "absolute hidden transition-all top-20 right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop"
                      }
                    >
                      <Link
                        to="/profile"
                        className="px-6 py-3 hover:bg-[#EDF7EE] flex hover:rounded-tr-[10px] hover:rounded-tl-[10px] transition-all"
                      >
                        <p className="text-[#344335] text-sm font-medium">
                          View Profile
                        </p>
                      </Link>
                      <li
                        onClick={handleLogout}
                        className="px-6 py-3 hover:bg-[#EDF7EE] hover:rounded-bl-[10px] hover:rounded-br-[10px] transition-all flex item-center gap-2"
                      >
                        <img src={logout_icon} alt="logout" />
                        <p className="text-[#344335] text-sm font-medium">Log Out</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="px-4 md:px-6 py-6">{children}</div>
               </div>
          </div>
        </>
     );
}
 
export default AgentSidebar;
import React from "react";
import { Add } from "@mui/icons-material";
import AgentDataTable from '../../../tables/Agent/Data';
import DataCapturePage from './DataCapture';

const AgentDataPage = () => {

    const [isShow, setShow] = React.useState(false);
    const handleFarmerData = () =>{
       setShow(!isShow);
    }

    return ( 
        <>

        {/*  */}
       <DataCapturePage isCreateCat={isShow} onClose={handleFarmerData} /> 

        <div className="bg-white rounded-[8px] border border-[#ECEFEC] px-4 py-4 flex justify-between items-center">
            <div>
            <p className="text-primary text-lg font-semibold leading-6">
                Farmer Data
            </p>
            </div>
            <div className="flex">
                 <button
                    type="submit"
                    onClick={handleFarmerData}
                    className="bg-primary 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Add Farmer Data
            <Add style={{ fontSize: "18px" }} />
          </button>
        </div>
      </div>

      <AgentDataTable />
    </>
  );
};

export default AgentDataPage;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import SearchComponent from "../../../components/Search";
import { debounce } from "@mui/material";
import { fetchAgents } from "../../../redux/Agents/agentsActions";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import note_icon from "../../../assets/icons/note.svg";

const AdminAgentsTable = () => {
  const dispatch = useAppDispatch();
  const { agents, loading } = useAppSelector((state) => state.agents);
  const [searchValue, setSearchValue] = useState("");

  // const handleProfile = (id: string) => {
  //   navigate(`/farmers-profile/${id}`);
  // };

  const handleSearch = useMemo(
    () =>
      debounce((val: string) => {
        console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  useEffect(() => {
    dispatch(fetchAgents());
  }, [dispatch]);

  return (
    <>
      <div className="mt-6 pb-3 bg-white rounded-[8px]">
        <div className="">
          <div className="w-full p-4">
            <SearchComponent
              placeholder="Search"
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          {loading ? (
            <div>
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            </div>
          ) : (
            <>
              {agents?.length === 0 ? (
                <div className="flex flex-col justify-center items-center min-h-[500px]">
                  <div className="w-[64px] h-[64px] rounded-full flex justify-center items-center bg-[#EDF7EE]">
                    <img src={note_icon} alt="note" />
                  </div>
                  <p className="text-[#344335] text-sm font-semibold mt-2">
                    No data gathered yet
                  </p>
                </div>
              ) : (
                <table className="w-full text-left">
                  <thead className="text-primary text-sm ">
                    <tr>
                      <th scope="col" className="px-4 py-4 font-semibold ">
                        First Name
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Last Name
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Phone Number
                      </th>

                      <th scope="col" className="px-4 py-4 font-semibold">
                        State
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Local Government
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {agents?.map((agent, index) => {
                      return (
                        <tr
                          key={index}
                          // onClick={() => handleProfile("345")}
                          className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                        >
                          <th
                            scope="row"
                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                          >
                            {agent?.first_name || "N/A"}
                          </th>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {" "}
                            {agent?.last_name || "N/A"}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {" "}
                            {agent?.phone_number || "N/A"}
                          </td>

                          <td className="py-4 px-4 text-[#5C715E]">
                            {" "}
                            {agent?.state || "N/A"}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {agent?.lga || "N/A"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminAgentsTable;

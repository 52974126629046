import React from "react";
import Header from "../../../components/Header";
// import { CardWithBorderStatus } from "../../../components/Cards";
import AdminFarmersTable from "../../../tables/Admin/Farmers";

const AdminFarmersPage = () => {
  return (
    <>
      <Header text="Farmer" />

      {/* Cards */}

      {/* Table */}
      <AdminFarmersTable />
    </>
  );
};

export default AdminFarmersPage;

import React, { useEffect } from "react";
import agrilensLogo from "../../../assets/icons/agrilens_white.svg";
import warning from "../../../assets/icons/warning.svg";
import { Formik, Form, FormikHelpers } from "formik";
import { tokenValidator } from "../../../validationSchema/validator";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { userLogin } from "../../../redux/Auth/authActions";

const AgentLoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, isAuth } = useAppSelector((state) => state.auth);

  interface Values {
    token: string;
  }

  const handleSubmit = (values: Values) => {
    dispatch(
      userLogin({
        token: values.token,
      })
    );
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/data");
    }
  }, [isAuth, navigate]);

  return (
    <>
      <div className="relative flex overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[350px] h-[350px] rounded-full blur-[150px]"></div>
        <div className="w-[40%] bg-primary min-h-screen relative hidden md:block">
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <img src={agrilensLogo} alt="logo" className="w-[336px] h-[94px]" />
          </div>
        </div>
        <div className="w-full md:w-[60%] px-4 md:px-0 flex justify-center flex-col min-h-screen">
          <div className="min-w-full md:min-w-[32rem] mx-auto">
            <h6 className="text-primary font-semibold text-lg">Welcome Back</h6>
            <p className="text-[#5C715E] text-sm font-medium max-w-lg">
              Enter your unique token below to continue
            </p>
            {/* form */}
            <Formik
              initialValues={{
                token: "",
              }}
              validationSchema={tokenValidator}
              onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
              ) => {
                handleSubmit(values);
              }}
            >
              {({
                handleChange,
                isSubmitting,
                handleSubmit,
                dirty,
                isValid,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="mt-8" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="token"
                      className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                      Unique Token
                    </label>
                    <input
                      type="text"
                      name="token"
                      placeholder="Enter your unique token"
                      className={
                        touched.token && errors.token
                          ? "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4"
                          : "appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                      }
                      value={values.token}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.token && errors.token ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.token && errors.token}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      disabled={loading || !(isValid && dirty)}
                      className="bg-primary 
                                disabled:bg-[#ABB6AC] 
                                rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                    >
                      Validate Token
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentLoginPage;

import React from "react";

type RankType = {
  children: React.ReactNode;
};

export const Verified = ({ children }: RankType) => {
  return (
    <div className="bg-[#2B8C34] border-[1px] border-[#2B8C34] text-[#fff] rounded-full w-[48px] h-[48px]  flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
    </div>
  );
};

export const Active = ({ children }: RankType) => {
  return (
    <div className="bg-[#2B8C34] border-[1px] border-[#2B8C34] text-[#fff] rounded-full w-[48px] h-[48px]  flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
    </div>
  );
};

export const Unverified = ({ children }: RankType) => {
  return (
    <div className="bg-[#B92020] border-[1px] border-[#B92020] text-[#fff] rounded-full w-[48px] h-[48px]  flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
    </div>
  );
};

export const Fraudulent = ({ children }: RankType) => {
  return (
    <div className="bg-[#B92020] border-[1px] border-[#B92020] text-[#fff]  rounded-full w-[48px] h-[48px]  flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
    </div>
  );
};

export const Incomplete = ({ children }: RankType) => {
  return (
    <div className="bg-[#FFC508] border-[1px] border-[#FFC508] text-[#fff] rounded-full w-[48px] h-[48px]  flex flex-col items-center justify-center">
      <p className="font-medium text-base leading-6 ">{children}</p>
    </div>
  );
};

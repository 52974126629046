import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import AdminLoginPage from "./pages/Admin/Auth/login";
import AdminOverviewPage from "./pages/Admin/Overview/overview";
import AdminFarmersPage from "./pages/Admin/Farmers";
import AdminFarmersProfile from "./tables/Admin/Farmers/FarmersProfile";
import AgentLoginPage from "./pages/Agent/Auth/login";
import AgentDataPage from "./pages/Agent/Data";
import AgentSidebar from "./components/Sidebar/agentSidebar";
import AgentFarmersProfile from "./tables/Agent/Data/FarmersProfile";
import AdminAgentsPage from "./pages/Admin/Agents";
import FarmerLoginPage from "./pages/Farmers/Auth/login";

import AgentRoute from "./components/PrivateRoutes/agentRoute";
import AdminRoute from "./components/PrivateRoutes/adminRoute";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<AgentLoginPage />} />
          <Route
            path="/data"
            element={
              <AgentRoute>
                <AgentSidebar title="Data" children={<AgentDataPage />} />
              </AgentRoute>
            }
          />
          <Route
            path="/data/:id/:phone_number"
            element={
              <AgentRoute>
                <AgentSidebar title="Data" children={<AgentFarmersProfile />} />
              </AgentRoute>
            }
          />

          {/* ADMIN */}
          <Route path="/admin" element={<AdminLoginPage />} />
          <Route
            path="/overview"
            element={
              <AdminRoute>
                <Sidebar title="Overview" children={<AdminOverviewPage />} />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/farmers"
            element={
              <AdminRoute>
                <Sidebar title="Farmers" children={<AdminFarmersPage />} />
              </AdminRoute>
            }
          />
          <Route
            path="/agents"
            element={
              <AdminRoute>
                <Sidebar title="Farmers" children={<AdminAgentsPage />} />
              </AdminRoute>
            }
          />
          <Route
            path="/farmers-profile/:id/:phone_number"
            element={
              <AdminRoute>
                <Sidebar title="Farmers" children={<AdminFarmersProfile />} />
              </AdminRoute>
            }
          />

          {/* FARMERS SECTION */}
          <Route path="/register" element={<FarmerLoginPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

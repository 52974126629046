import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../../components/Search";
import { debounce } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchAgentFarmers } from "../../../redux/Farmers/farmerActions";
import note_icon from "../../../assets/icons/note.svg";
import PaginationComponent from "../../../components/Pagination/pagination";
import "../../../../src/index.css";

const AdminFarmersTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  // const [typeID, setTypeID] = useState(1);
  const { farmers, loading, total } = useAppSelector((state) => state.farmer);
  const [currentPage, setCurrentPage] = useState(1);

  const handleProfile = (id: string, phone_number: string) => {
    navigate(`/farmers-profile/${id}/${phone_number}`);
  };

  const handleSearch = useMemo(
    () =>
      debounce((val: string) => {
        // console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    dispatch(
      fetchAgentFarmers({
        page: page,
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchAgentFarmers({
        page: 1,
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className="mt-6 bg-white rounded-[8px]">
        <div className="">
          <div className="w-full p-4">
            <SearchComponent
              placeholder="Search"
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          {loading ? (
            <div>
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            </div>
          ) : (
            <>
              {farmers?.length === 0 ? (
                <div className="flex flex-col justify-center items-center min-h-[500px]">
                  <div className="w-[64px] h-[64px] rounded-full flex justify-center items-center bg-[#EDF7EE]">
                    <img src={note_icon} alt="note" />
                  </div>
                  <p className="text-[#344335] text-sm font-semibold mt-2">
                    No data gathered yet
                  </p>
                </div>
              ) : (
                <table className="w-full text-left">
                  <thead className="text-primary text-sm ">
                    <tr>
                      <th scope="col" className="px-4 py-4 font-semibold ">
                        First Name
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Last Name
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Phone Number
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Registration Status
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        State
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Local Government
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Farmer Status
                      </th>
                      <th scope="col" className="px-4 py-4 font-semibold">
                        Farm Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {farmers?.map((farmer, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() =>
                            handleProfile(farmer?.id, farmer?.phone_number)
                          }
                          className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                        >
                          <th
                            scope="row"
                            className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                          >
                            {farmer?.first_name || "N/A"}
                          </th>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {" "}
                            {farmer?.last_name || "N/A"}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {farmer?.phone_number || "N/A"}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {" "}
                            <div
                              className={`${
                                farmer?.is_complete
                                  ? "border-[1px] border-[#2B8C34] text-[#2B8C34]"
                                  : "border-[1px] border-[#B92020] text-[#B92020]"
                              } rounded-[16px] flex items-center justify-center px-2 py-1 w-fit `}
                            >
                              <p className="text-sm font-medium leading-[18px]">
                                {farmer?.is_complete
                                  ? "Complete"
                                  : "Incomplete"}
                              </p>
                            </div>
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {" "}
                            {farmer?.state || "N/A"}
                          </td>
                          <td className="py-4 px-4 text-[#5C715E]">
                            {farmer?.lga || "N/A"}
                          </td>

                          {/* farmer Tag */}
                          <td className="py-4 px-4 text-[#5C715E] capitalize">
                            <div
                              className={`${
                                farmer?.farmer_tag === "complete" ||
                                farmer?.farmer_tag === "verified" ||
                                farmer?.farmer_tag === "active"
                                  ? "success"
                                  : farmer?.farmer_tag === "unverified"
                                  ? "failure"
                                  : farmer?.farmer_tag === "incomplete"
                                  ? "pending"
                                  : farmer?.farmer_tag === "likely fraudulent"
                                  ? "error"
                                  : "na-light"
                              } rounded-[16px] flex items-center justify-center px-2 py-1 w-fit `}
                            >
                              <p className="text-sm font-medium leading-[18px]">
                                {farmer?.farmer_tag || "Nill"}
                              </p>
                            </div>
                          </td>
                          <td className="py-4 px-4 text-[#5C715E] capitalize">
                            <div
                              className={`${
                                farmer?.farm_tag === "complete" ||
                                farmer?.farm_tag === "verified" ||
                                farmer?.farm_tag === "active"
                                  ? "success"
                                  : farmer?.farm_tag === "inactive"
                                  ? "failure"
                                  : farmer?.farm_tag === "incomplete" ||
                                    farmer?.farm_tag ===
                                      "incomplete farm capture"
                                  ? "pending"
                                  : farmer?.farm_tag === "season_inactive"
                                  ? "error"
                                  : farmer?.farm_tag === "low_cultivation"
                                  ? "info"
                                  : "na-light"
                              } rounded-[16px] flex items-center justify-center px-2 py-1 w-fit `}
                            >
                              <p className="text-sm font-medium leading-[18px]">
                                {farmer?.farm_tag || "Nill"}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
        <div>
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange(page)}
            totalItems={total}
            pageSize={20}
          />
        </div>
      </div>
    </>
  );
};

export default AdminFarmersTable;

import { ExpandMore } from "@mui/icons-material";
import Bar from "../../../components/BarChart/BarChart";
import map from "../../../assets/images/map.png";
import Chart from "../../../components/AreaChart/Area";
import TinyBarChart from "../../../components/BarChart/TinyBarChart";
import states from "../../../data/states.json";
import overview from "../../../data/overview.json";
import { useEffect, useState } from "react";

const AdminOverviewPage = () => {
  const [isToggleState, setIsToggleState] = useState(false);
  const [selectedState, setSelectedState] = useState("Abia");
  const [details, setDetails] = useState<
    | {
        state: string;
        farmers: number;
        crop_yield: number;
        average_income: number;
        popular_livestock_or_crop: string;
      }
    | undefined
  >({
    state: "",
    farmers: 0,
    crop_yield: 0,
    average_income: 0,
    popular_livestock_or_crop: "",
  });

  const BarData = [
    {
      name: "2019",
      farmerCropYield: 4000,
      averagePerCropYield: 2400,
    },
    {
      name: "2020",
      farmerCropYield: 3000,
      averagePerCropYield: 1398,
    },
    {
      name: "2021",
      farmerCropYield: 2000,
      averagePerCropYield: 9800,
    },
    {
      name: "2022",
      farmerCropYield: 2780,
      averagePerCropYield: 3908,
    },
    {
      name: "2023",
      farmerCropYield: 1890,
      averagePerCropYield: 4800,
    },
    {
      name: "2024",
      farmerCropYield: 2390,
      averagePerCropYield: 3800,
    },
  ];

  const AreaData = [
    {
      name: "2019",
      data: 10,
    },
    {
      name: "2020",
      data: 12,
    },
    {
      name: "2021",
      data: 9,
    },
    {
      name: "2022",
      data: 11,
    },
    {
      name: "2023",
      data: 10,
    },
    {
      name: "2024",
      data: 10,
    },
  ];

  const AgentsBarData = [
    {
      name: "Jan",
      data: 4000,
    },
    {
      name: "Feb",
      data: 3000,
    },
    {
      name: "Mar",
      data: 2000,
    },
    {
      name: "Apr",
      data: 2780,
    },
    {
      name: "May",
      data: 1890,
    },
    {
      name: "Jun",
      data: 2390,
    },
    {
      name: "Jul",
      data: 2780,
    },
    {
      name: "Aug",
      data: 1890,
    },
    {
      name: "Sep",
      data: 2390,
    },
    {
      name: "Oct",
      data: 2780,
    },
    {
      name: "Nov",
      data: 1890,
    },
    {
      name: "Dec",
      data: 2390,
    },
  ];

  useEffect(() => {
    if (selectedState) {
      const filteredData = overview?.overview?.find(
        (data) => data?.state === selectedState
      );
      // console.log(filteredData);
      setDetails(filteredData);
    }
  }, [selectedState]);

  return (
    <>
      <div className="bg-white rounded-[8px] px-4 py-4 flex  justify-between items-center">
        <div>
          <p className="text-primary text-lg font-semibold leading-6">
            Overview
          </p>
        </div>

        <div className="relative">
          <div
            className="flex border-[#ECEFEC]  border-[1px] rounded-lg px-[10px] py-[11px] items-center gap-1 "
            onClick={() => setIsToggleState(!isToggleState)}
          >
            <p className="text-[#96A397] text-sm font-medium leading-[18px]">
              State:
            </p>
            <div className="flex items-center cursor-pointer">
              <p className="text-[#344335] text-sm font-medium leading-[18px]">
                {selectedState || "Select State"}
              </p>
              <ExpandMore style={{ color: "#344335", fontSize: "16px" }} />
            </div>
          </div>

          {isToggleState && (
            <div className="absolute w-full z-[100]">
              <div
                className=" rounded-[8px] absolute py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll z-[100] "
                style={{
                  boxShadow:
                    "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                {states?.States.map((state, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedState(state?.name);
                        setIsToggleState(false);
                      }}
                      className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] ${
                        index < states?.States?.length - 1 ? "mb-1" : ""
                      } ${
                        selectedState === state?.name
                          ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                          : "text-[#344335] bg-white"
                      }`}
                    >
                      <p className=" leading-5 text-sm font-medium">
                        {state?.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-5 border border-[#ECEFEC] py-4 px-4 bg-white rounded-[8px] flex justify-between ">
        <div>
          <p className="text-2xl font-semibold leading-[30px] text-[#344335] pb-6">
            Insights
          </p>

          {/* FARMERS */}
          <div className="pb-6">
            <p className="text-sm font-semibold leading-[18px] text-[#5C715E] pb-1">
              Farmers
            </p>
            <p className="text-[22px] font-semibold leading-[36px] text-[#344335]">
              {details?.farmers || "0"}
            </p>
          </div>

          {/* CROP YIELD */}
          <div className="pb-6">
            <p className="text-sm font-semibold leading-[18px] text-[#5C715E] pb-1">
              Crop Yield
            </p>
            <p className="text-[22px] font-semibold leading-[36px] text-[#344335]">
              {details?.crop_yield || "0"} Tonnes
            </p>
          </div>

          {/*AVERAGE INCOME*/}
          <div className="pb-6">
            <p className="text-sm font-semibold leading-[18px] text-[#5C715E] pb-1">
              Average Income
            </p>
            <p className="text-[22px] font-semibold leading-[36px] text-[#344335]">
              ₦{details?.average_income?.toLocaleString() || "0"}
            </p>
          </div>

          {/*POPULAR CROPS?LIVESTOCK*/}
          <div className="">
            <p className="text-sm font-semibold leading-[18px] text-[#5C715E] pb-1">
              Popular Crops/Livestock
            </p>
            <p className="text-[22px] font-semibold leading-[36px] text-[#344335]">
              {details?.popular_livestock_or_crop || "N/A"}
            </p>
          </div>
        </div>

        <div>
          <img src={map} alt="map" className="" />
        </div>
      </div>

      <div className="mt-5 border border-[#ECEFEC] py-4 px-4 bg-white rounded-[8px] ">
        <div className="flex justify-between">
          <div className="flex gap-6">
            <div className="pr-5">
              <p className="text-sm font-semibold leading-[18px] pb-3 text-[#5C715E]">
                Complete Registration
              </p>
              <p className="text-[23px] font-semibold leading-[36px] pb-3 text-[#344335]">
                40,000
              </p>
              {/* <p className="text-[12px] font-semibold leading-[16px] pb-6 text-[#2B8C34]">
                See More
              </p> */}
            </div>
            <div className="pr-5">
              <p className="text-sm font-semibold leading-[18px] pb-3 text-[#5C715E]">
                Incomplete Registration
              </p>
              <p className="text-[23px] font-semibold leading-[36px] pb-3 text-[#344335]">
                2000
              </p>
              {/* <p className="text-[12px] font-semibold leading-[16px] pb-6 text-[#2B8C34]">
                See More
              </p> */}
            </div>
          </div>
          <div className="">
            <div className="flex border-[#ECEFEC] border-[1px] rounded-lg px-[10px] py-[11px] items-center gap-1  ">
              <div className="flex items-center cursor-pointer">
                <p className="text-[#344335] text-sm font-medium leading-[18px]">
                  Monthly
                </p>
                <ExpandMore style={{ color: "#96A397", fontSize: "16px" }} />
              </div>
            </div>
          </div>
        </div>
        {/* BAR CHART */}
        <div>
          <div className="flex gap-6 mb-5">
            <div className="flex gap-2 items-center">
              <div className="rounded-full w-2 h-2 bg-[#2B8C34]"></div>
              <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E]">
                Complete Registration
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <div className="rounded-full w-2 h-2 bg-[#FFC508]"></div>
              <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E]">
                Incomplete Registration
              </p>
            </div>
          </div>

          <div>
            <Bar height={230} data={BarData} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="mt-5 border border-[#ECEFEC] py-4 px-4 bg-white rounded-[8px]">
          <div className="flex justify-between mb-[22px]">
            <div>
              <p className="text-sm text-[#344335] font-semibold pb-3">
                Crop Yield
              </p>
              <p className="text-[22px] text-[#344335] font-semibold leading-[36px] pb-3">
                7 Tonnes
              </p>
            </div>

            <div className="flex border-[#ECEFEC] border-[1px] rounded-lg px-[10px] py-[11px] items-center gap-1 h-fit  ">
              <div className="flex items-center cursor-pointer">
                <p className="text-[#344335] text-sm font-medium leading-[18px]">
                  All Crops
                </p>
                <ExpandMore style={{ color: "#96A397", fontSize: "16px" }} />
              </div>
            </div>
          </div>

          <div>
            <Chart data={AreaData} height={230} color="#2B8C34" id="#crops1" />
          </div>
        </div>
        <div className="mt-5 border border-[#ECEFEC] py-4 px-4 bg-white rounded-[8px]">
          <div className="flex justify-between mb-[22px]">
            <div>
              <p className="text-sm text-[#344335] font-semibold pb-3">
                Average Income
              </p>
              <p className="text-[22px] text-[#344335] font-semibold leading-[36px] pb-3">
                ₦488,587
              </p>
            </div>

            <div className="flex border-[#ECEFEC] border-[1px] rounded-lg px-[10px] py-[11px] items-center gap-1 h-fit  ">
              <div className="flex items-center cursor-pointer">
                <p className="text-[#344335] text-sm font-medium leading-[18px]">
                  Monthly
                </p>
                <ExpandMore style={{ color: "#96A397", fontSize: "16px" }} />
              </div>
            </div>
          </div>

          <div>
            <Chart data={AreaData} height={230} color="#FFC508" id="#crops2" />
          </div>
        </div>
        <div className="mt-5 border border-[#ECEFEC] py-4 px-4 bg-white rounded-[8px]">
          <div className="flex justify-between mb-[22px]">
            <div>
              <p className="text-sm text-[#344335] font-semibold pb-3">
                Agents
              </p>
              <p className="text-[22px] text-[#344335] font-semibold leading-[36px] pb-3">
                4,000
              </p>
            </div>

            <div className="flex border-[#ECEFEC] border-[1px] rounded-lg px-[10px] py-[11px] items-center gap-1 h-fit  ">
              <div className="flex items-center cursor-pointer">
                <p className="text-[#344335] text-sm font-medium leading-[18px]">
                  Monthly
                </p>
                <ExpandMore style={{ color: "#96A397", fontSize: "16px" }} />
              </div>
            </div>
          </div>

          <div>
            <TinyBarChart height={230} data={AgentsBarData} size={12} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminOverviewPage;

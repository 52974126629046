import React, { useEffect, useState } from "react";
import {
  Add,
  ContentPaste,
  ExpandMore,
  FilePresent,
  UploadFile,
} from "@mui/icons-material";
import DrawerComponent from "../../../../components/Drawers/drawer";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  fetchReports,
  fetchReportsOverview,
  fetchReportsTypes,
} from "../../../../redux/Farmers/farmerActions";
import moment from "moment";
import Bar from "../../../../components/BarChart/BarChart";
import { StackedAreaChartTwo } from "../../../../components/AreaChart/StackedAreaChart";
import { reportType } from "../../../../redux/Farmers/farmers";

type InfoType = {
  id?: string | undefined;
};

const AdminFarmReportPage = ({ id }: InfoType) => {
  const dispatch = useAppDispatch();
  const [typeID, setTypeID] = useState("overview");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCreateReport, setIsOpenCreateReport] = useState(false);
  const [report, setReport] = useState<any>({});
  const [graphData, setGraphData] = useState([]);
  const [cropYield, setCropYield] = useState<any>({});
  const [income, setIncome] = useState<any>({});
  const { reports, reportsOverview, reportsLoading } = useAppSelector(
    (state) => state.farmer
  );

  const reportTypes = [
    {
      id: "overview",
      title: "Overview",
    },
    {
      id: "crop_yield",
      title: "Crop Yield",
    },
    {
      id: "income",
      title: "Income Statement",
    },
    {
      id: "others",
      title: "Other Report",
    },
  ];

  const toggleType = (typeId: string) => {
    setTypeID(typeId);
  };

  useEffect(() => {
    if (typeID === "income") {
      dispatch(fetchReportsTypes({ id: id, type: typeID })).then((res) => {
        if (res?.type === "fetchReportsTypes/fulfilled") {
          setIncome(res?.payload?.data);
        }
      });
    } else if (typeID === "overview") {
      dispatch(fetchReportsOverview({ id: id, type: "overview" })).then(
        (res) => {
          if (res?.type === "fetchReportsOverview/fulfilled") {
            const response = res?.payload?.data;
            const formattedData = response?.expenditure_reports
              ?.map((expenditureReport: any) => {
                const matchingIncomeReport = response?.income_reports?.find(
                  (incomeReport: any) =>
                    incomeReport.name === expenditureReport.name
                );
                if (matchingIncomeReport) {
                  return {
                    name: expenditureReport.name,
                    farmer_income: matchingIncomeReport.farmer_income,
                    farmer_expenditure: expenditureReport.farmer_income,
                  };
                }
                return null;
              })
              .filter(Boolean);
            setGraphData(formattedData);
          }
        }
      );
    } else {
      dispatch(fetchReports({ id: id, type: "others" }));
    }
    dispatch(fetchReportsTypes({ id: id, type: "crop_yield" })).then((res) => {
      if (res?.type === "fetchReportsTypes/fulfilled") {
        setCropYield(res?.payload?.data);
      }
    });
  }, [dispatch, id, typeID]);

  return (
    <>
      <div className="border flex justify-between items-center border-[#ECEFEC] bg-[#FFFFFF] rounded-lg py-3 px-4">
        <ul className="flex gap-5 items-center">
          {reportTypes.map((item) => (
            <li
              key={item.id}
              onClick={() => toggleType(item.id)}
              className={
                typeID === item.id
                  ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer relative"
                  : "flex gap-1 items-center pb-1 cursor-pointer relative"
              }
            >
              <p
                className={
                  typeID === item.id
                    ? "text-primary text-sm font-semibold"
                    : "text-[#96A397] text-sm font-semibold"
                }
              >
                {item.title}
              </p>
            </li>
          ))}
        </ul>
        <div>
          <button
            type="submit"
            onClick={() => setIsOpenCreateReport(true)}
            className="bg-[#EDF7EE] 
                                         flex justify-center items-center gap-2
                                        rounded-lg px-5 py-3 text-primary text-sm font-medium transition-all"
          >
            <Add style={{ color: "#2B8C34", fontSize: "16px" }} />
            Add Report
          </button>
        </div>
      </div>

      {/* empty state */}
      {typeID === "overview" ? (
        <div>
          <div className="mt-5 border border-[#ECEFEC] py-4 px-4 bg-white rounded-[8px] ">
            <div className="flex justify-between">
              <div className="flex gap-6">
                <div className="pr-5">
                  <p className="text-sm font-semibold leading-[18px] pb-3 text-[#5C715E]">
                    Income
                  </p>
                  <p className="text-[23px] font-semibold leading-[36px] pb-3 text-[#344335]">
                    {reportsOverview?.avg_income ? (
                      <>
                        ₦
                        {parseFloat(reportsOverview?.avg_income).toLocaleString(
                          "en",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </>
                    ) : (
                      "0.00"
                    )}
                  </p>
                  {/* <p className="text-[12px] font-semibold leading-[16px] pb-6 text-[#2B8C34]">
                See More
              </p> */}
                </div>
                <div className="pr-5">
                  <p className="text-sm font-semibold leading-[18px] pb-3 text-[#5C715E]">
                    Expenditure
                  </p>
                  <p className="text-[23px] font-semibold leading-[36px] pb-3 text-[#344335]">
                    {reportsOverview?.avg_expenditure ? (
                      <>
                        ₦
                        {parseFloat(
                          reportsOverview?.avg_expenditure
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || "0.00"}
                      </>
                    ) : (
                      "0.00"
                    )}
                  </p>
                  {/* <p className="text-[12px] font-semibold leading-[16px] pb-6 text-[#2B8C34]">
                See More
              </p> */}
                </div>
              </div>
              <div className="">
                <div className="flex border-[#ECEFEC] border-[1px] rounded-lg px-[10px] py-[11px] items-center gap-1  ">
                  <div className="flex items-center cursor-pointer">
                    <p className="text-[#344335] text-sm font-medium leading-[18px]">
                      Yearly
                    </p>
                    <ExpandMore
                      style={{ color: "#96A397", fontSize: "16px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* BAR CHART */}
            <div className="flex justify-end mt-[30px]">
              <div className="flex gap-6 mb-5">
                <div className="flex gap-2 items-center">
                  <div className="rounded-full w-2 h-2 bg-[#2B8C34]"></div>
                  <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E]">
                    Income
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="rounded-full w-2 h-2 bg-[#B92020]"></div>
                  <p className="text-sm font-semibold leading-[18px] text-[#7C8D7E]">
                    Expenditure
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="">
                <StackedAreaChartTwo data={graphData} height={180} />
              </div>
            </div>
          </div>

          <div className="mt-5 border border-[#ECEFEC] py-4 px-4 bg-white rounded-[8px]">
            <div className="flex justify-between mb-[22px]">
              <div>
                <p className="text-sm text-[#344335] font-semibold pb-3">
                  Crop Yield
                </p>
                <p className="text-[22px] text-[#344335] font-semibold leading-[36px] pb-3">
                  {cropYield?.avg_crop_yield ? (
                    <>
                      {parseFloat(cropYield?.avg_crop_yield).toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      ) ||
                        "0.00" ||
                        0}{" "}
                      {Number(cropYield?.avg_crop_yield) <= 1
                        ? "Tonne"
                        : "Tonnes"}
                    </>
                  ) : (
                    "0.00"
                  )}
                </p>
              </div>

              <div className="flex border-[#ECEFEC] border-[1px] rounded-lg px-[10px] py-[11px] items-center gap-1 h-fit  ">
                <div className="flex items-center cursor-pointer">
                  <p className="text-[#344335] text-sm font-medium leading-[18px]">
                    All
                  </p>
                  <ExpandMore style={{ color: "#96A397", fontSize: "16px" }} />
                </div>
              </div>
            </div>

            <div>
              {/* <TinyBarChart height={230} data={AgentsBarData} size={32} /> */}
              <Bar
                height={230}
                data={cropYield?.crop_yield_reports}
                size={32}
              />
            </div>
          </div>
        </div>
      ) : typeID === "crop_yield" ? (
        <>
          {reportsLoading ? (
            <div className="mt-[10%] flex justify-center items-center">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              {cropYield?.crop_yield_reports?.length !== 0 ? (
                <div className="mt-6 grid grid-cols-3 gap-6">
                  {cropYield?.crop_yield_reports?.map(
                    (report: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className=""
                          onClick={() => setReport(report)}
                        >
                          {/* 1 */}
                          <div
                            className="px-3 py-4 bg-white border-[1px] border-[ECEFEC]  rounded-lg w-full h-fit cursor-pointer"
                            onClick={() => setIsOpen(true)}
                          >
                            <p className="text-sm font-medium leading-5 text-[#344335] mb-4">
                              {report?.farmer_crop_yield
                                ? report?.farmer_crop_yield
                                : "0"}{" "}
                              {Number(report?.farmer_crop_yield) <= 1
                                ? "Tonne"
                                : "Tonnes"}
                            </p>
                            <p className="text-sm font-medium leading-5 text-[#5C715E] mb-4">
                              Reported by{" "}
                              <span className="capitalize font-semibold text-[#344335]">
                                {" "}
                                {report?.reported_by || "nill"}
                              </span>{" "}
                              for{" "}
                              <span className="capitalize font-semibold text-[#344335]">
                                {report?.name || "nill"}
                              </span>
                            </p>
                            {report?.document ? (
                              <div className="flex p-2 gap-4 rounded-lg border-[1px] border-[#2B8C34] mb-[19px]">
                                <div className="rounded-full p-2 bg-[#E5F3E7] flex justify-center items-center w-[35px] h-[35px]">
                                  <FilePresent
                                    style={{
                                      fontSize: "16px",
                                      color: "#2B8C34",
                                    }}
                                  />
                                </div>
                                <div>
                                  <p className="text-xs font-semibold leading-4 text-[#344335] mb-1">
                                    Green Valley Farms Report
                                  </p>
                                  <p className="text-xs font-medium leading-4 text-[#96A397]">
                                    200 KB
                                  </p>
                                </div>
                              </div>
                            ) : null}

                            <div className=" w-full">
                              {/* {report?.created_by ? (
                          <div className="flex gap-1 items-center">
                            <div className="flex justify-center items-center  bg-[#DBFFDF] rounded-full w-6 h-6">
                              <p className="text-[#2B8C34] text-[10px] font-medium leading-[14px]">
                                CA
                              </p>
                            </div>
                            <p className="text-sm font-medium text-[#5C715E] leading-[18px]">
                              Celina Avong
                            </p>
                          </div>
                        ) : (
                          "nill"
                        )} */}

                              <div className="flex justify-end">
                                <p className="text-[#96A397] text-xs font-medium leading-4">
                                  {moment(report?.created).format("LT")}|{" "}
                                  {moment(report?.created).format("ll")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center min-h-[500px]">
                  <ContentPaste
                    style={{ color: "#7C8D7E", fontSize: "24px" }}
                  />

                  <p className="text-[#5C715E] text-sm font-semibold mt-2">
                    No Report
                  </p>
                  <div className="mt-2 max-w-xs text-center">
                    <p className="text-[#5C715E] text-sm font-medium">
                      Please fill the reports to see
                      <br /> more data
                    </p>
                  </div>
                  <button
                    type="submit"
                    onClick={() => setIsOpenCreateReport(true)}
                    className="bg-primary mt-5 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                  >
                    Create Report
                  </button>
                </div>
              )}
            </>
          )}
        </>
      ) : typeID === "income" ? (
        <>
          {reportsLoading ? (
            <div className="mt-[10%] flex justify-center items-center">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              {income?.income_reports?.length !== 0 ? (
                <div className="mt-6 grid grid-cols-3 gap-6">
                  {income?.income_reports?.map((report: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className=""
                        onClick={() => setReport(report)}
                      >
                        {/* 1 */}
                        <div
                          className="px-3 py-4 bg-white border-[1px] border-[ECEFEC]  rounded-lg w-full h-fit cursor-pointer"
                          onClick={() => setIsOpen(true)}
                        >
                          <p className="text-sm font-medium leading-5 text-[#344335] mb-4">
                            ₦
                            {report?.farmer_income
                              ? Number(report?.farmer_income)?.toLocaleString()
                              : "0"}
                          </p>
                          <p className="text-sm font-medium leading-5 text-[#5C715E] mb-4">
                            Reported by{" "}
                            <span className="capitalize font-semibold text-[#344335]">
                              {" "}
                              {report?.reported_by || "nill"}
                            </span>{" "}
                            for{" "}
                            <span className="capitalize font-semibold text-[#344335]">
                              {report?.name || "nill"}
                            </span>
                          </p>
                          {report?.document ? (
                            <div className="flex p-2 gap-4 rounded-lg border-[1px] border-[#2B8C34] mb-[19px]">
                              <div className="rounded-full p-2 bg-[#E5F3E7] flex justify-center items-center w-[35px] h-[35px]">
                                <FilePresent
                                  style={{
                                    fontSize: "16px",
                                    color: "#2B8C34",
                                  }}
                                />
                              </div>
                              <div>
                                <p className="text-xs font-semibold leading-4 text-[#344335] mb-1">
                                  Green Valley Farms Report
                                </p>
                                <p className="text-xs font-medium leading-4 text-[#96A397]">
                                  200 KB
                                </p>
                              </div>
                            </div>
                          ) : null}

                          <div className=" w-full">
                            {/* {report?.created_by ? (
                          <div className="flex gap-1 items-center">
                            <div className="flex justify-center items-center  bg-[#DBFFDF] rounded-full w-6 h-6">
                              <p className="text-[#2B8C34] text-[10px] font-medium leading-[14px]">
                                CA
                              </p>
                            </div>
                            <p className="text-sm font-medium text-[#5C715E] leading-[18px]">
                              Celina Avong
                            </p>
                          </div>
                        ) : (
                          "nill"
                        )} */}

                            <div className="flex justify-end">
                              <p className="text-[#96A397] text-xs font-medium leading-4">
                                {moment(report?.created).format("LT")}|{" "}
                                {moment(report?.created).format("ll")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center min-h-[500px]">
                  <ContentPaste
                    style={{ color: "#7C8D7E", fontSize: "24px" }}
                  />

                  <p className="text-[#5C715E] text-sm font-semibold mt-2">
                    No Report
                  </p>
                  <div className="mt-2 max-w-xs text-center">
                    <p className="text-[#5C715E] text-sm font-medium">
                      Please fill the reports to see
                      <br /> more data
                    </p>
                  </div>
                  <button
                    type="submit"
                    onClick={() => setIsOpenCreateReport(true)}
                    className="bg-primary mt-5 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                  >
                    Create Report
                  </button>
                </div>
              )}
            </>
          )}
        </>
      ) : typeID === "others" ? (
        <>
          {reportsLoading ? (
            <div className="mt-[10%] flex justify-center items-center">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              {reports?.length !== 0 ? (
                <div className="mt-6 grid grid-cols-3 gap-6">
                  {reports?.map((report: reportType, index: number): any => {
                    return (
                      <div
                        key={index}
                        className=""
                        onClick={() => setReport(report)}
                      >
                        <div
                          className="px-3 py-4 bg-white border-[1px] border-[ECEFEC]  rounded-lg w-full h-fit cursor-pointer"
                          onClick={() => setIsOpen(true)}
                        >
                          <p className="text-sm font-medium leading-5 text-[#344335] mb-4">
                            {report?.description || "nill"}
                          </p>
                          {report?.document ? (
                            <div className="flex  gap-4 rounded-lg mb-[19px] max-h-[150px] w-full">
                              <img
                                src={report?.document}
                                alt="Document"
                                className="rounded-lg w-full"
                              />
                            </div>
                          ) : // <div className="flex p-2 gap-4 rounded-lg border-[1px] border-[#2B8C34] mb-[19px]">
                          //   <div className="rounded-full p-2 bg-[#E5F3E7] flex justify-center items-center w-[35px] h-[35px]">
                          //     <FilePresent
                          //       style={{ fontSize: "16px", color: "#2B8C34" }}
                          //     />
                          //   </div>
                          //   <div>
                          //     <p className="text-xs font-semibold leading-4 text-[#344335] mb-1">
                          //       Green Valley Farms Report
                          //     </p>
                          //     <p className="text-xs font-medium leading-4 text-[#96A397]">
                          //       200 KB
                          //     </p>
                          //   </div>
                          // </div>
                          null}

                          <div className="flex gap-3 justify-between items-center w-full">
                            {report?.created_by ? (
                              <div className="flex gap-1 items-center">
                                <div className="flex justify-center items-center  bg-[#DBFFDF] rounded-full w-6 h-6">
                                  <p className="text-[#2B8C34] text-[10px] font-medium leading-[14px]">
                                    {report?.created_by?.charAt(0)}
                                  </p>
                                </div>
                                <p className="text-sm font-medium text-[#5C715E] leading-[18px]">
                                  {report?.created_by || "Nill"}
                                </p>
                              </div>
                            ) : (
                              "nill"
                            )}

                            <div className="">
                              <p className="text-[#96A397] text-xs font-medium leading-4">
                                {moment(report?.created).format("LT")}|{" "}
                                {moment(report?.created).format("ll")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center min-h-[500px]">
                  <ContentPaste
                    style={{ color: "#7C8D7E", fontSize: "24px" }}
                  />

                  <p className="text-[#5C715E] text-sm font-semibold mt-2">
                    No Report
                  </p>
                  <div className="mt-2 max-w-xs text-center">
                    <p className="text-[#5C715E] text-sm font-medium">
                      Please fill the reports to see
                      <br /> more data
                    </p>
                  </div>
                  <button
                    type="submit"
                    onClick={() => setIsOpenCreateReport(true)}
                    className="bg-primary mt-5 
                                disabled:bg-[#ABB6AC] flex gap-2 items-center 
                                rounded-lg py-3 px-5 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
                  >
                    Create Report
                  </button>
                </div>
              )}
            </>
          )}
        </>
      ) : null}

      {/* DRAWERS */}
      {/* REPORT DETAILS Drawer component */}
      <DrawerComponent
        title="Report"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className="mt-[30px]">
          <div className="mb-6 ">
            <p className="text-sm leading-5 text-[#344335]">Author</p>
            <p className="text-sm font-medium  leading-[18px] pt-1 text-[#344335]">
              {report?.created_by || "Nill"}
            </p>
          </div>

          <div className="mb-6 ">
            <p className="text-sm leading-5 text-[#344335]">Phone Number</p>
            <p className="text-sm font-medium  leading-[18px] pt-1 text-[#344335]">
              {report?.phone_number || "Nill"}
            </p>
          </div>

          <div className="mb-6 ">
            <p className="text-sm leading-5 text-[#344335]">Date Created</p>
            <p className="text-sm font-medium  leading-[18px] pt-1 text-[#344335]">
              {moment(report?.created).format("LT")}|{" "}
              {moment(report?.created).format("ll")}
            </p>
          </div>

          {report?.report_type !== "others" && (
            <>
              <div className="mb-6 ">
                <p className="text-sm leading-5 text-[#344335]">Value</p>
                <p className="text-sm font-medium  leading-[18px] pt-1 text-[#344335] ">
                  <span className="capitalize">
                    {" "}
                    {report?.report_type !== "crop yield" ? "₦" : null}
                    {report?.value !== "crop yield"
                      ? report?.value?.toLocaleString()
                      : report?.value}
                  </span>
                  {report?.report_type === "crop yield" ? "Tonne(s)" : null}
                </p>
              </div>

              <div className="mb-6 ">
                <p className="text-sm leading-5 text-[#344335]">Reported By</p>
                <p className="text-sm font-medium  leading-[18px] pt-1 text-[#344335] capitalize">
                  {report?.reported_by || "Nill"}
                </p>
              </div>

              <div className="mb-6 ">
                <p className="text-sm leading-5 text-[#344335]">
                  Reported Date
                </p>
                <p className="text-sm font-medium  leading-[18px] pt-1 text-[#344335] capitalize">
                  {report?.report_for_date || "Nill"}
                </p>
              </div>
            </>
          )}

          <div className="mb-6 ">
            <p className="text-sm leading-5 text-[#344335]">Report Summary</p>
            <div className="py-3 px-4 bg-white border border-[#96A397] rounded-lg mt-1">
              <p className="text-sm font-medium  leading-[20px]  text-[#344335]">
                {report?.description || "Nill"}
              </p>
            </div>
          </div>

          {report?.document ? (
            <div className="mb-6 ">
              <p className="text-sm leading-5 text-[#344335]">
                Document Attachment
              </p>
              {report?.document ? (
                <div className="flex mt-1  gap-4 rounded-lg mb-[19px] max-h-[150px] w-full">
                  <img
                    src={report?.document}
                    alt="Document"
                    className="rounded-lg w-full"
                  />
                </div>
              ) : (
                // <div className="flex px-2 py-4 gap-4 rounded-lg border-[1px] border-[#2B8C34] mt-1">
                //   <div className="rounded-full p-2 bg-[#E5F3E7] flex justify-center items-center w-[35px] h-[35px]">
                //     <FilePresent style={{ fontSize: "16px", color: "#2B8C34" }} />
                //   </div>
                //   <div>
                //     <p className="text-sm font-semibold leading-4 text-[#344335] mb-1">
                //       Green Valley Farms Report
                //     </p>
                //     <p className="text-sm font-medium leading-4 text-[#96A397]">
                //       200 KB
                //     </p>
                //   </div>
                // </div>
                "Nill"
              )}
            </div>
          ) : null}
        </div>
      </DrawerComponent>

      {/* CREATE REPORT Drawer component */}
      <DrawerComponent
        title="Create Report"
        isOpen={isOpenCreateReport}
        onClose={() => setIsOpenCreateReport(false)}
      >
        <div className="mt-[30px]">
          <div className="mb-6 ">
            <p className="text-sm leading-5 text-[#344335]">Report Summary</p>
            <textarea
              placeholder="Report Summary"
              className="w-full outline-none h-[150px] resize-none py-3 px-4 bg-white border border-[#96A397] rounded-lg mt-1 text-sm font-medium  leading-[20px]  text-[#344335]"
            />
          </div>

          <div className="">
            <p className="text-sm leading-5 text-[#344335]">
              Document Attachment
            </p>
            <div className="py-4 px-4 cursor-pointer bg-white border border-[#96A397] rounded-lg mt-1 flex items-center flex-col">
              <div className="rounded-[200px] p-2 bg-[#DDE2DE] flex justify-center items-center w-[40px] h-[40px] border border-[#DDE2DE] mb-3">
                <UploadFile style={{ fontSize: "24px", color: "#7C8D7E" }} />
              </div>
              <p className="text-sm font-semibold  leading-[18px]  text-[#2B8C34]">
                Click to upload{" "}
              </p>

              <p className="text-xs font-medium  leading-[16px]  text-[#5C715E] pt-1">
                Docs, PDF, SVG, PNG, JPG or GIF (max. 800x400px)
              </p>
            </div>
          </div>

          <button
            type="submit"
            disabled
            className="bg-[#EDF7EE] 
                                         flex justify-center items-center gap-2 mt-[32px]
                                        rounded-lg px-5 py-3 text-primary text-sm font-medium transition-all disabled:text-white  disabled:bg-[#ABB6AC] disabled:cursor-not-allowed"
          >
            Save
          </button>
        </div>
      </DrawerComponent>
    </>
  );
};

export default AdminFarmReportPage;
